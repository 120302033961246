<template>
    <div class="row">
        <div class="col-12 mb-4">
            <div class="card">
                <div class="card-body">
                    <router-link class="btn btn-default float-right" to="/manualproduct" title="New product">Create new product</router-link>                    
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card datacard">
                <div class="card-header">
                    <div class="text-white"> Pending products</div>
                </div>
                <div class="card-body table-responsive">
                    <table class="table table-bordered table-striped mb-0">
                        <thead>
                            <tr><th></th><th>Reference</th><th>Product title</th><th>State</th></tr>
                        </thead>
                        <tbody>                            
                            <tr v-for="p in manualProducts" :key="p.eanRef">
                                <td><ElipsisButton v-on:menuaction="onMenuAction" v-bind:items="getMenuItems(p)" v-bind:id="p.eanRef" /></td>
                                <td>{{p.eanRef}}</td>
                                <td>{{p.productTitle}} {{p.productVariantTitle}}</td>
                                <td>{{getStateName(p.status)}}</td>
                            </tr>                           
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="4" class="border-none" style="height: 200px; background-color: rgb(239, 239, 239);"></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <modal v-if="showTransferModal" @close="showTransferModal = false" @ok="transferProduct" hasOk="true" hasCancel="true" isWarning="true">
                <div slot="body">
                    This will transfer the product to Dansk e-logistik <br/>
                    You will not be able to use the product in orders before it is received - this may take up till 10 min.
                </div>
                <h3 slot="header">Send product to warehouse ?</h3>
                <span slot="footer"></span>
        </modal>
        <modal v-if="showDeleteModal" @close="showDeleteModal = false" @ok="deleteProduct" hasOk="true" hasCancel="true" isWarning="true">
                <div slot="body">
                    This will only delete your product from the pending list, not from the catalog.
                </div>
                <h3 slot="header">Delete product ?</h3>
                <span slot="footer"></span>
        </modal>

    </div>
</template>
<script>
import {mapGetters,mapActions} from "vuex";
import ElipsisButton from "./ElipsisButton"
import modal from "./Modal"
export default {
    components:{
        ElipsisButton,modal
    },
    data:function(){
        return { 
            actionId:null,
            showTransferModal:false,
            showDeleteModal:false      
        }
    },
    computed:{
        ...mapGetters(["manualProducts","isLoaded"]),    

    },
    methods:{
        ...mapActions(["loadManualProducts","deleteManualProduct","sendManualProduct","clearOutManualProducts"]),
        getStateName(state){
            switch(state){
                case 1:
                    return "Draft";
                case 2:
                    return "Pending";
                case 3:
                    return "Edited";
                default:
                    return "Unknown";
            }
        },
        async transferProduct(){
            console.log("transfer", this.actionId)
            await this.sendManualProduct(this.actionId);
            this.showTransferModal=false;
            
        },
        async deleteProduct(){
            console.log("deleting",this.actionId)
            await this.deleteManualProduct(this.actionId)
            this.showDeleteModal=false
        },   
        onMenuAction(action,id){
            console.log(`perform ${action} on ${id}`)
            this.actionId = id
            if(action=='edit'){
                let prod = this.manualProducts.find(p=>p.eanRef ===id)
                this.$router.push({name:"manualproduct",params:{editItem:prod}});
            }
            if(action=='transfer'){
                this.showTransferModal=true;
            }
            if(action=='delete'){
                this.showDeleteModal=true;
            }
        },
        getMenuItems(mp){  
            console.log(mp)          
            if(mp.status==1){
                return [               
                {link:"#",name:"Edit", action:"edit"},
                {link:"#",name:"Send to warehouse", action:"transfer"},
                {link:"#",name:"Delete", action:"delete"},
                ]
            }
            else if(mp.status==2 || mp.status==3) {
                return [ 
                {link:"#",name:"Edit", action:"edit"},
                {link:"#",name:"Send update", action:"transfer"},                              
                
                ]
            } else {
                return [{link:"#",name:"No actions", action:"what"}]
            }

        },    
    },
    created(){
        if(!this.isLoaded.includes("manualproducts")){            
            this.loadManualProducts()
        }
        this.clearOutManualProducts()

        
    }
}
</script>