<template>

<div class="col-12 mt-5" v-if="allUnboundSkus.length">
    <div class="card card-defult" v-if="loading">
        <div class="card-header"><b>Unbound SKU's</b></div>
        <div class="card-body">
            <div class="text-center">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                </div>
        </div>
    </div>
    <div class="dash-card datacard card" v-else>
        <div id="unboundV" class="card-header bg-warning border-warning text-white">
            <b>Unbound SKU's</b>
        </div>
        <div class="card-body table-responsive-md p-0 mb-0">
              <table class="table table-striped table-bordered table-hover mb-0">                
                <thead>                    
                       <tr>
                        <th></th>
                        <th>SKU number</th>
                        <th>Alternate SKU</th>
                        <th>Description</th>
                        <th>Date created</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="s in allUnboundSkus" v-bind:key="s.skuId">
                        <td>
                            <ElipsisButton v-on:menuaction="onMenuAction" v-bind:items="menuitems" v-bind:id="s.skuId" />
                        </td>
                        <td>{{s.skuNumber}}</td>
                        <td>{{s.altSKU}}</td>
                        <td>{{s.skuDescription}}</td>
                        <td>{{s.dateCreated}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

</template>

<script>
import ElipsisButton from "./ElipsisButton";
import {mapGetters} from "vuex";
export default {
    data:function(){
        return {
            menuitems:[                
                {link:"#",name:"Bind SKU", action:"bind"},                
            ],
            search:undefined
        }
    },    
    components:{
        ElipsisButton
    },
    computed:{
        ...mapGetters(["allUnboundSkus","isLoaded"]),
        loading(){
            return !this.isLoaded.includes("unboundskus");
        }       
    },
    methods:{
        
         onMenuAction(action, id){
             if(action==='bind') {
                 this.$router.push({name:'skubindings',params:{id}})
             }
            //console.log(`perform ${action} on ${id}`);
        }
    }    
}
</script>