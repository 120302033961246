<template>
<div class="col-12 mt-5" v-if="allDuplicates.length">
    <div class="card card-defult" v-if="loading">
        <div class="card-header"><b>Unbound SKU's</b></div>
        <div class="card-body">
            <div class="text-center">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                </div>
        </div>
    </div>
    <div class="dash-card datacard card border" style="" v-else>
        <div id="dupsV"  class="card-header bg-danger border-danger text-white">
            <b>Dublicate ean/references</b>
        </div>
        <div class="card-body table-responsive-md p-0 mb-0">
              <table class="table table-striped table-bordered table-hover mb-0">                
                <thead>
                    <tr>                       
                        <th></th>
                        <th>Ean/Reference</th>
                        <th>Product ID</th>                        
                        <th>Product Name</th>
                        <th>Variant Name</th>
                        <th>Variant ID</th>
                        <th>Date created</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="d in allDuplicates" v-bind:key="d.delProductVariantId">
                        <td>
                            <ElipsisButton v-on:menuaction="onMenuAction" v-bind:items="menuitems" v-bind:id="d.delProductVariantId" />
                        </td>
                        <td>{{d.ean}}</td>
                        <td>{{d.clientProductId}}</td>                        
                        <td>{{d.productName}}</td>
                        <td>{{d.productVariantName}}</td>
                        <td>{{d.clientVariantId}}</td>
                        <td>{{d.dateCreated}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <modal v-if="showDeleteModal" @close="showDeleteModal = false" @ok="deleteProduct()" hasOk="true" hasCancel="true" isWarning="true">
        <div slot="body">
            This will delete the duplicate product permanently, remember to rebind the remaining product if bindings are lost.
        </div>
        <h3 slot="header">Delete duplicate ?</h3>
        <span slot="footer"></span>
    </modal>
    <modal v-if="showDeleteErrorModal" @ok="showDeleteErrorModal = false" hasOk="true" isWarning="true">
            <div slot="body">
                {{ errorText }}
            </div>
            <h3 slot="header">An error occurred</h3>
            <span slot="footer"></span>
    </modal>
</div>

</template>

<script>
import ElipsisButton from "./ElipsisButton";
import {mapGetters,mapActions} from "vuex";
import modal from "./Modal";
import Axios from "axios"
export default {
    data:function(){
        return {
            menuitems:[                
                {link:"#",name:"Delete", action:"delete"},                
            ],
            search:undefined,
            showDeleteModal:false,
            showDeleteErrorModal:false,
            actionId:null,
            errorText:""
        }
    },    
    components:{
        ElipsisButton,modal
    },
    computed: {
        ...mapGetters(["allDuplicates","isLoaded","theBaseUrl"]),
        
        loading() {
            return !this.isLoaded.includes('duplicates');
        }  
    },
    methods:{
        ...mapActions(["refresh"]),
         deleteProduct(){
            console.log("delete",this.actionId);
            let me = this
            Axios.delete(`${this.theBaseUrl}/api/catalog/${me.actionId}`).then(r => {
                //this.$store.dispatch('invalidate', { resources: 'all' });
                me.refresh('duplicates');
                me.refresh('duplicates');
                console.log(r.data);
            }).catch(function (reason) {
                console.log("Error deleting duplicate", reason);
                
                switch(reason.response.status) {              
                    case 404:
                        me.errorText="The product ean/reference could not be deleted, there may be active orders containing this ean/reference."
                        break;
                    case 403:
                        me.errorText="You do not have sufficient permissions to perform this operation"
                        break;
                    default:
                        me.errorText="An error ocurred, the operation could not be performed"
                        break;
                }                                
                me.showDeleteErrorModal = true;
            });
            me.showDeleteModal=false;
        },     
         onMenuAction(action, id){
             this.actionId = id;
             console.log(`perform ${action} on ${id}`);
             if(action =='delete') {
                 this.showDeleteModal=true;
             }
            
        }
    }    
}
</script>
