<template>
    <div class="row">
    <div class="col"> 
        <form>
            <div class="form-group form-horizontal">
                <label class="font-weight-bold" for="findref">Search</label>
                <input type="text" id="findref" v-model="search" class="form-control" placeholder="Use exact ordernumbers to filter/search for orders" />
            </div>
        </form>
        <div class="card card-defult" v-if="loading">
            <div class="card-header text-center"><b>Orders</b></div>
            <div class="card-body">
                <div class="text-center">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        <span class="sr-only">Loading...</span>
                    </div>
            </div>
        </div>        
        <div class="datacard card border-1" v-else>
            <div class="card-header text-white text-center">Orders</div>
            <div class="datacard card-body table-responsive mb-0">
            <table class="table table-striped table-bordered table-hover mb-0">
            <thead>
                    <tr>
                        <th></th>
                        <th>Received</th>
                        <th>Order number</th>
                        <th>Status</th>
                        <th>StatusDate</th>
                        <th>Track &amp; trace</th>                                                
                    </tr>
                </thead>
                <tbody>
                <tr v-for="o in filteredOrders" v-bind:key="o.orderNumber">
                    <td><ElipsisButton v-on:menuaction="onMenuAction" v-bind:items="menuitems" v-bind:id="o.orderId" /></td>
                        <td>{{o.dateCreated}}</td>
                        <td>{{o.orderNumber}}</td>
                        <td>{{o.status}}</td>                        
                        <td>{{o.statusDate}}</td>
                        <td v-html="trackingLinks(o.tracking)"></td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="6" style="height:50px"></td>
                    </tr>
                </tfoot>
            </table>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import ElipsisButton from "./ElipsisButton"
import {mapGetters,mapActions} from "vuex";
export default {
    components:{
        ElipsisButton
    },
    data:function(){
        return {
            menuitems:[                
                {link:"#",name:"View history", action:"history"},                
                /*{link:"#",name:"Request pause", action:"pause"},
                {link:"#",name:"Request cancellation", action:"cancel",icon:"fa-exclamation-circle"},*/
            ],
            search:undefined
        }
    },
    methods: {
        
        ...mapActions(["loadOrderData","loadOrderByNumber"]),
        onMenuAction(action, id){            
            console.log(`perform ${action} on ${id}`);
            if(action=='history'){
                this.$router.push({name:"orderhistory",params:{id}});
            }
        },
        trackingLinks: function (tl) {
            let html = ""
            //console.log(tl);
            if (tl) {
                let tarr = tl.split(',');
                var llen = tarr.length;
                for (var i = 0; i < llen; i++) {
                    let t = tarr[i].trim();
                    html += `<a target="_blank" href="http://tracking.dansk-e-logistik.dk/track?number=${t}&redir=none">${t}</a>`;
                    if (i+1 < llen)
                    {
                        html +=','
                    }
                }
             

            }
            return html;
        }
    },
    computed: {    
        ...mapGetters(["allOrders","isLoaded"]),  
        loading() {
            return !this.isLoaded.includes('orders');
        },  
        filteredOrders: function () {
            var plist = this.allOrders.slice(0);
                        
            if (this.search) {
                var search = this.search.trim();
                plist = plist.filter(function (row) {
                    if (row) {

                        //var b = row.orderNumber.search(new RegExp(search, "i"))>=0;
                        var b = row.orderNumber.startsWith(search);
                        if (b)
                            return true;
                    }
                    return false;
                });

                //If the current filter returns nothing, search the database
                if (plist.length == 0) {
                    this.loadOrderByNumber(search);
                } else {
                    if (!plist.some(function (o) { if (o.orderNumber == search) return true; return false; })) {
                        this.loadOrderByNumber(search);
                    }
                }
            }
            return plist;
        },
    },
    created(){
        if(!this.isLoaded.includes("orders")){
            
            this.loadOrderData();
        }

        
    },
    mounted(){
        window.scrollTo(0,0);
    }
}
</script>