<template>    
<div class="row">
    <div class="col"> 
        <div class="card mb-4">
            <div class="card-body">
                <form>
                    <div class="form-row">
                        <div class="form-group col-md">
                            <label for="findsku">SKU</label>
                            <input type="text" id="findsku" class="form-control" v-model="SKU" />
                        </div>
                        <div class="form-group col-md">
                            <label for="findreason">Reason</label>
                            <select class="form-control" id="findreason" v-model="reason">
                                <option value="0">All</option>
                                <option value="5">Stock entry because of error</option>
                                <option value="6">Destocking because of error</option>
                                <option value="7">Goods reception</option>
                                <option value="8">Dammaged in stock</option>
                                <option value="9">Dammaged return from customer</option>
                                <option value="10">Dammaged on arrival</option>
                                <option value="12">Customer returns</option>
                                <option value="13">Stock entry on client request</option>
                                <option value="14">Destocking on client request</option>
                                <option value="15">Stock entry because of goods found</option>
                                <option value="16">Destocking because of goods lost</option>
                            </select>
                        </div>
                        <div class="form-group col-md">
                            <DatePicker labelText="From date:" :initDate="fromDate" v-model="fromDate" :id="'datepicker1'" :state="DatePickerState"></DatePicker>
                        </div>
                        <div class="form-group col-md">
                            <DatePicker labelText="To date:" :initDate="toDate" v-model="toDate" :id="'datepicker2'" :state="DatePickerState"></DatePicker>

                        </div>
                        <div class="form-group col-md" style="padding-top:31px">
                            <button class="btn btn-default form-control" v-on:click.prevent="refreshData()">Search <i class="fa fa-search fa-lg"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="card card-defult" v-if="loading">
            <div class="card-header"><b>Inventory</b></div>
            <div class="card-body">
                <div class="text-center">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        <span class="sr-only">Loading...</span>
                    </div>
            </div>
        </div>
        <div class="datacard inv card border-1" v-else>
            <div class="card-header text-white text-center">Inventory</div>
            <div class="datacard card-body table-responsive mb-0">
            <table class="table table-striped table-bordered table-hover mb-0">
                <thead class="">
                    <tr>                        
                        <th>SKU</th>
                        <th>SKU Description</th>
                        <th>Transaction Date</th>
                        <th>Reason</th>                        
                        <th>Change in stock</th>
                        <th>Physical stock</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="(t,idx) in transactions" v-bind:key="idx">                    
                    <td>{{t.sku}}</td>
                    <td>{{t.skuDescription}}</td>
                    <td>{{t.transactionDate}}</td>
                    <td>{{t.reason}}</td>                        
                    <td>{{t.changeInStock}}</td>
                    <td>{{t.inStock}}</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DatePicker from './DatePicker'
import moment from 'moment'

import {mapActions,mapGetters} from "vuex";
export default {
components: {
    DatePicker
    },
    data: function () {
        return {
            message: " Message from Inventory",
            //transactions: [],
            fromDate:'',
            toDate:'',
            reason:'7',
            SKU: '',   
            DatePickerState:{opened:'none'}        
        }
    },
    computed: {
        ...mapGetters(["allInventoryTransactions","isLoaded"]),
        transactions(){
            //console.log("transactions:",this.allInventoryTransactions);
            return this.allInventoryTransactions;
        },
        loading(){
            return !this.isLoaded.includes("inventory")
        }
    },
    methods: {
        ...mapActions(["loadInventoryTransactions","setUnloaded"]),
        refreshData: function () {
            this.DatePickerState.opened="search";
            this.setUnloaded("inventory");

            let p = {
                fromdate:this.fromDate,
                todate:this.toDate,                
                sku:this.SKU.trim()
            };
            if(this.reason!=='0'){
                p.reason=this.reason;
            }
            
            this.loadInventoryTransactions(p);
        }
    },
    created() {
        var from = moment(Date.now());
        var to = from.clone();
        from.subtract(10, 'days');
        this.fromDate = from.format("YYYY-MM-DD");
        this.toDate = to.format("YYYY-MM-DD");
        let p ={
            fromdate: from.format('YYYY-MM-DD'),
            todate: to.format('YYYY-MM-DD'),
            reason: this.reason
        };
        this.loadInventoryTransactions(p);
    },
    /*mounted() {

        var url = 'api/inventory?reason=7&fromdate=' + this.fromDate;
        console.log(url);
        this.$http.get(url).then(r=> {            
            this.transactions = r.data;
            this.loading = false;
        });

    }  */
}
</script>