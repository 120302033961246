<template>
    <div class="container">
        <div clas="row">
            <div class="mx-auto mt-5" style="max-width:450px">
                <div class="card login-form">
                <div class="card-header text-white" style="background-color:#6f799e">
                    Please Sign In
                </div>
                <div class="card-body">
                    <form role="form" autocomplete="on">
                        <fieldset>
                            <div class="form-group">
                                <input class="form-control" placeholder="E-mail" name="email" type="email" autofocus="" v-model="useremail">
                            </div>
                            <div class="form-group">
                                <input class="form-control" placeholder="Password" name="password" type="password" v-model="userpassword">
                            </div>
                            <!-- Change this to a button or input when using this as a form -->
                            <input type="submit" class="btn btn-lg btn-success btn-block" v-on:click.prevent="doLogin()" value="Login" />
                        </fieldset>
                    </form>
                    <div style="max-width:150px;margin: 10px auto;text-align:center"><a href="#" v-on:click.prevent="forgotPw()">Reset my password!</a></div>
                    <div v-if="LogInStatus.status>200" class="alert alert-danger">Login failed:<br/>{{statusText}}</div>
                     <div class="alert common-alert alert-success" v-if="passwordReset">
                            Instructions have been sent to your email.
                        </div>
                </div>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions} from "vuex";
import {mapGetters} from "vuex";
export default {
    data: function(){
        return {
            useremail:"",
            userpassword:"",
            accesstoken:"",
            statusText:"",
            parentAuth:this.$parent.isauth,                        
        }
    },
    computed:{
        ...mapGetters(["LoggedIn","LogInStatus","passwordReset"]),
    },
    methods:{
        
        ...mapActions(["login","initData","loadAccounts","resetPW"]),        
        async doLogin(){
            
            console.log("login");
                 
                try
                {
                await this.login({userEmail:this.useremail,userPass:this.userpassword});                              
                if(this.LogInStatus.status>200){                    
                    if(this.LogInStatus.status==401){
                        this.statusText="Username or password incorrect"
                    } else {
                        this.statusText=this.LogInStatus.statusText
                    }

                } else {
                    this.$parent.token =btoa(this.useremail +'@'+this.userpassword)
                    //this.loginStatus = this.$store.state.loginStatus.statusText;
                    //console.log("from login -",this.$store.state.logInStatus.statusText)
                    this.loadAccounts();
                }
                }
                catch(err){
                    console.log("login failed:", err)
                    this.statusText = "Unexpected error"
                }
            
        },
        async forgotPw(){
            console.log("trying to reset password");
            await this.resetPW({Email:this.useremail});

            //console.log("forgotPw");
        }
    }    
}
</script>