<template>
<div>
    <div class="alert alert-info">
            <div class="row">
                <div class="col-md-10">
                    <b>Totals in hangig orderlines</b>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-info pull-right" v-on:click="goDash()">Back to dashboard</button>
                </div>
            </div>
    </div>
     <div class="datacard card border-1 mb-3">
            <div class="card-header text-white text-center">Totals</div>
            <div class="datacard card-body table-responsive mb-0">
                <table class="table table-striped table-bordered table-hover mb-0">
                    <thead>                    
                        <tr>
                            <th>Ean/Reference</th>
                            <th>Orderline text</th>
                            <th>Quantity</th>
                            <th>Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="l in orderlines" v-bind:key="l.ean">
                            <td>{{l.ean}}</td>
                            <td>{{l.orderlineText}}</td>
                            <td>{{l.quantity}}</td>
                            <td>
                                {{l.error}}
                            </td>
                        </tr>
                    </tbody>                
                </table>
            </div>
     </div>
</div>
</template>
<script>
import Axios from "axios";
export default {
     data: function () {
        return {
            orderlines: []
        }
    },
    methods: {
        goDash: function () {
            this.$router.push('/dash');
        }
    },
    mounted() {
        let baseUrl=this.$store.getters.theBaseUrl;
        Axios.get(`${baseUrl}/api/orders/hanging/lines`).then(r => {
            this.orderlines = r.data;
        });
        //this.$store.dispatch('loadHangingOrders');


    }
}
</script>