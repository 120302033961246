<template>
    <div>
        <div v-if="order" class="alert alert-info">
            <div class="row">
                <div class="col-md-10">
                    <b>Order number:</b>{{order.orderNumber}},
                    <b>Date:</b> {{order.dateCreated}}
                </div>
                <div class="col-md-2">
                    <button class="btn btn-info pull-right" style="margin-top:-5px" v-on:click="goBack()">Back to orders</button>
                </div>
            </div>
        </div>
        <div class="datacard card border-1 mb-3">
            <div class="card-header text-white text-center">Currently linked SKU's</div>
            <div class="datacard card-body table-responsive mb-0">
                <table class="table table-striped table-bordered table-hover no-footer mb-0">
                <thead>

                    <tr><th>Status</th><th>Date</th></tr>
                    <tr v-for="(h,idx) in orderhistory" v-bind:key="idx">
                        <td>{{h.statusName}}</td>
                        <td>{{h.statusDate}}</td>
                    </tr>
                </thead>
            </table>
            </div>
        </div>

    </div>
</template>
<script>
import Axios from "axios";
export default {
    props: ['id'],
    data: function () {
        return {
            orderhistory: []
            

        }
    },
    methods: {
        goBack: function () {
            this.$router.push('/orders');
        }
    },
    computed: {
        order() {            
            var thisid = this.id;
            var o = this.$store.state.orders.filter(function (row) {
                return row.orderId === thisid;
            });

            if(o[0])
                return o[0];
            return null;
            
        }
    },
    mounted() {
        let baseUrl=this.$store.getters.theBaseUrl;
        Axios.get(`${baseUrl}/api/orders/${this.id}/history`).then(r => {
            this.orderhistory = r.data;
        }, err => {
            console.log("caught in orderhistory (2)", err);           
        });

    }
}
</script>