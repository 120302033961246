<template>
    <div class="row">
        <div class="col-12">
            <div class="card mb-md-4 mb-3">
                <div class="card-body">
                <button class="btn btn-default float-right" @click="NewOrder">Create new order</button>         
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="datacard card">
                <div class="card-header"><div class="text-white">In progress</div></div>
                <div class="card-body table-responsive"> 
                    <table class="table table-bordered table-striped mb-0 border-bottom-0">
                        <thead>
                            <tr>
                                <th colspan="1"></th><th>Order number</th><th>Customer</th><th>Address</th><th>Email</th><th>Status</th>
                            </tr>
                        </thead>
                        <tbody>                        
                        <tr v-for="mo in manualOrders" v-bind:key="mo.orderNumber">
                            <td v-if="false"><input type="checkbox" v-bind:checked="mo.Selected" @change="setSelected(mo.orderNumber)"></td>
                            <td><ElipsisButton v-on:menuaction="onMenuAction" v-bind:items="getMenuItems(mo)" v-bind:id="mo.orderNumber" /></td>
                            <td>{{mo.orderNumber}}</td>                            
                            <td>{{mo.orderData.address.company}} {{mo.orderData.address.firstName}} {{mo.orderData.address.lastName}}</td>
                            <td>{{mo.orderData.address.address1}}, {{mo.orderData.address.zip}} {{mo.orderData.address.city}}, {{mo.orderData.address.country}}</td>
                            <td>{{mo.orderData.email}}</td>
                            <td>{{statusIs(mo.orderStatus)}}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="border-none" colspan="7" style="height:225px;background-color:#efefef;"></td>
                            </tr>
                        </tfoot>
                    </table>
                                 
                </div>
            </div>
            <modal v-if="showTransferModal" @close="showTransferModal = false" @ok="transferOrder" hasOk="true" hasCancel="true" isWarning="true">
                <div slot="body">
                    This will transfer the order to be processed by Dansk e-logistik <br/>
                    When the wharehouse has started picking the order, you will no longer be able to change or cancel the order.
                </div>
                <h3 slot="header">Send order to warehouse ?</h3>
                <span slot="footer"></span>
            </modal>
            <modal v-if="showCancelModal" @close="showCancelModal = false" @ok="cancelOrder" hasOk="true" hasCancel="true" isWarning="true">
                <div slot="body">
                    This will cancel your order at Dansk e-logistik <br/>
                    After cancelling this order you will no longer be able to make updates or changes to the order.
                </div>
                <h3 slot="header">Cancel order ?</h3>
                <span slot="footer"></span>
            </modal>
            <modal v-if="showDeleteModal" @close="showDeleteModal = false" @ok="deleteOrder" hasOk="true" hasCancel="true" isWarning="true">
                <div slot="body">
                    This will delete your draft order permantly
                </div>
                <h3 slot="header">Delete order ?</h3>
                <span slot="footer"></span>
            </modal>    
        </div>

    </div>
</template>
<script>
import ElipsisButton from "./ElipsisButton"
import {mapGetters,mapActions} from "vuex";
import modal from "./Modal"
export default {
    components:{
        ElipsisButton,modal
    },
    data:function(){
        return {
            actionId:0,
            showTransferModal:false,
            showCancelModal:false,
            showDeleteModal:false,
            stdmenuitems:[                
                {link:"#",name:"Edit", action:"edit"},
                {link:"#",name:"Send to warehouse", action:"transfer"},
                {link:"#",name:"Cancel", action:"cancel"},
                {link:"#",name:"Delete", action:"delete"},
                /*{link:"#",name:"Request pause", action:"pause"},
                {link:"#",name:"Request cancellation", action:"cancel",icon:"fa-exclamation-circle"},*/
            ]
        }
    },
    computed:{
        ...mapGetters(["manualOrders","isLoaded"]),                   
    },
    methods:{
        ...mapActions(["loadManualOrders","deleteManualOrder","sendManualOrder","cancelManualOrder","clearOutManualOrders"]),
        getMenuItems(mo){  
            console.log(mo)          
            if(mo.orderStatus==1){
                return [               
                {link:"#",name:"Edit", action:"edit"},
                {link:"#",name:"Send to warehouse", action:"transfer"},
                {link:"#",name:"Send cancellation", action:"cancel"},
                {link:"#",name:"Delete", action:"delete"},
                {link:"#",name:"Copy", action:"copy"}                
                ]
            }
            else if(mo.orderStatus==2 || mo.orderStatus==3) {
                return [               
                {link:"#",name:"Edit", action:"edit"},
                {link:"#",name:"Send update", action:"transfer"},
                {link:"#",name:"Send cancellation", action:"cancel"},
                {link:"#",name:"Copy", action:"copy"}
                ]
            }
            else if(mo.orderStatus==4) {
                return [               
                {link:"#",name:"Copy", action:"copy"}
                ]
            } else {
                return [{link:"#",name:"No actions", action:"what"}]
            }

        },
        NewOrder(){
            this.$router.push({name:"manualorder",params:{}})
        },
        setSelected(orderNumber) {
            this.$store.commit('setManualOrderSelect',orderNumber)
        },
        onMenuAction(action,id){  
            this.actionId = id          
            console.log(`perform ${action} on ${id} `);
            if(action=='edit') {
                this.$router.push({name:"manualorder",params:{id}});
            }
            if(action=='transfer') {
                this.showTransferModal=true;
            }
            if(action=='cancel') {
                this.showCancelModal=true;
            }
            if(action=='delete') {
                this.showDeleteModal=true;
            }
            if(action=='copy') {
                this.$router.push({name:"manualorder",params:{id:id,iscopy:true}});
            }
            /*if(action=='history'){
                this.$router.push({name:"orderhistory",params:{id}});
            }*/
        },
        async transferOrder(){
            console.log("transfer order!", this.actionId);
            await this.sendManualOrder(this.actionId);
            this.showTransferModal=false;
        },
        async cancelOrder(){
            console.log("cancel order!", this.actionId);
            await this.cancelManualOrder(this.actionId);
            this.showCancelModal=false;
        }, 
        async deleteOrder(){
            console.log("delete order!", this.actionId);
            await this.deleteManualOrder(this.actionId);
            this.showDeleteModal=false;
        },       
        statusIs(n) {
            
            switch(n) {
                case 0:
                return "Unsaved draft";                    
                case 1: 
                return "Draft";
                case 2: 
                return "Awaiting shipment";
                case 3: 
                return "Edited";                
                case 4: 
                return "In progress (xx)";                
                case 5: 
                return "Shipped";                
                case 6: 
                return "Awaiting cancel";
                case 7: 
                return "Canceled";
                case 100:
                return "Error"; 
                case 1000:
                return "Archived";                 
                default:
                    return "Unknown";
            }
        }
    },
    created(){
        /*if(!this.isLoaded.includes("manualorders")) 
        {
                this.loadManualOrders()
        }*/
        this.clearOutManualOrders()                

  
    }
}
</script>