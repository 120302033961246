<template>
<div>
    <div class="row">
        <div class="col-sm">
            <div class="card bg-secondary text-white border-2 mb-1" v-if="loadingHanging">
                <div class="card-body ">                
                        <div class="pull-left">
                          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                            <span class="sr-only">Loading...</span>
                         </div>                        
                        <div class="pull-right">
                            <div style="font-size:40px" class="text-right">?</div>
                            <div>Hanging Orders</div>
                        </div>                
                </div>
            </div>
            <div class="card text-white border-2 mb-1" v-bind:class="{'bg-warning':hangingCnt,'bg-success':hangingCnt==0}" v-else>
                <div class="card-body ">                
                        <div class="pull-left">
                            <i v-if="hangingCnt" class="fa fa-exclamation-circle fa-5x"></i>
                            <i v-else class="fa fa-check-circle fa-5x"></i>
                        </div>
                        <div class="pull-right">
                            <div style="font-size:40px" class="text-right">{{hangingCnt}}</div>
                            <div>Hanging Orders</div>
                        </div>                
                </div>
                <div v-if="hangingCnt" class="card-footer bg-light btn" @click="scrollTo('hangingV')" v-bind:class="{'text-warning':hangingCnt,'text-success':hangingCnt==0}">
                    <div class="pull-left"><span class="text-primary">View details</span></div>
                    <div class="pull-right"><i class="fa fa-arrow-circle-right fa-lg"></i></div>
                </div>
            </div>
        </div>
        <div class="col-sm">
            <div class="card bg-secondary text-white border-2 mb-1" v-if="loadingUnbound">
                <div class="card-body ">                
                        <div class="pull-left">
                          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                            <span class="sr-only">Loading...</span>
                         </div>                        
                        <div class="pull-right">
                            <div style="font-size:40px" class="text-right">?</div>
                            <div>Unbound SKU's</div>
                        </div>                
                </div>
            </div>            
            <div class="card text-white mb-1" v-bind:class="{'bg-warning':unboundCnt,'bg-success':unboundCnt==0}" v-else>
                <div class="card-body">                
                        <div class="pull-left">
                            <i v-if="unboundCnt" class="fa fa-exclamation-circle fa-5x"></i>
                            <i v-else class="fa fa-check-circle fa-5x"></i>                           
                        </div>
                        <div class="pull-right ">
                            <div style="font-size:40px" class="text-right">{{unboundCnt}}</div>
                            <div>Unbound SKU's</div>
                        </div>                
                </div>
                <div v-if="unboundCnt" @click="scrollTo('unboundV')" class="btn card-footer bg-light" v-bind:class="{'text-warning':unboundCnt,'text-success':unboundCnt==0}">
                    <div class="pull-left"><span class="text-primary" >View details</span></div>
                    <div class="pull-right"><i class="fa fa-arrow-circle-right fa-lg"></i></div>
                </div>
            </div>
        </div>
        <div class="col-sm">
            <div class="card bg-secondary text-white border-2 mb-1" v-if="loadingDuplicates">
                <div class="card-body ">                
                        <div class="pull-left">
                          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                            <span class="sr-only">Loading...</span>
                         </div>                        
                        <div class="pull-right">
                            <div style="font-size:40px" class="text-right">?</div>
                            <div>Duplicates</div>
                        </div>                
                </div>
            </div>                
            <div class="card text-white mb-1" v-bind:class="{'bg-danger':dupsCnt,'bg-success':dupsCnt==0}"  v-else> 
                <div class="card-body">                
                        <div class="pull-left">
                            <i v-if="dupsCnt" class="fa fa-exclamation-circle fa-5x"></i>
                            <i v-else class="fa fa-check-circle fa-5x"></i>                              
                        </div>
                        <div class="pull-right">
                            <div style="font-size:40px" class="text-right">{{dupsCnt}}</div>
                            <div >Duplicates</div>
                        </div>                
                </div>
                <div v-if="dupsCnt" class="btn card-footer bg-light" @click="scrollTo('dupsV')" v-bind:class="{'text-danger':dupsCnt,'text-success':dupsCnt==0}">
                    <div class="pull-left"><span class="text-primary" >View details</span></div>
                    <div class="pull-right"><i class="fa fa-arrow-circle-right fa-lg"></i></div>
                </div>
            </div>
        </div>
    </div>
        <modal v-if="showGDPRModal" @close="MuteConsent()" @ok="GiveConsent()" hasOk="true" hasCancel="true" isConscent="true" size="xlarge" >
            <div slot="body">
                <p>
                    I henhold til EU lov vedr. beskyttelse af persondata skal vi have en databehandleraftale med alle vore kunder, da vi behandler persondata i kraft af at vi behandler dine ordrer.
                    <br/>Vi skal derfor bede dig om at acceptere en sådan aftale.<br />Aftalen er et krav for at vi fortsat kan levere vores service overfor dig som kunde.<br />
                </p>
                <p>Vores databehandleraftale finder du her <a href="/dpa-dansk-e-logistik.pdf" target="_blank">Databehandler aftale</a></p>
                <p>Du bedes venligst udfylde alle nedenstående felter og acceptere aftralen ved at trykke på "Accepter aftalen".<br/>
                Accepten du giver her er gældende i samme omfang som en almindelig underskrift.
                </p>
                <table style="width:100%">
                    <tr><td>Cvr Nummer</td><td><input type="text" placeholder="Firmaets cvr nummer" v-model="dpaCvr" class="form-control" /></td></tr>
                    <tr><td>Dataansvarlige</td><td><input type="text" placeholder="Indtast navn på dataansvarlige" v-model="dpaName" class="form-control" /></td></tr>
                    <tr><td>Firmaadresse</td><td><input type="text" placeholder="Vej, Postnummer, By, (Land)" v-model="dpaAddress" class="form-control" /></td></tr>
                    <tr><td>Email</td><td><input type="text" placeholder="Dataansvarliges email" v-model="dpaEmail" class="form-control" /></td></tr>                        
                </table>
                
            </div>
            <h3 style="align-content:center" slot="header">Du bedes acceptere vores databehandleraftale</h3>
            <span slot="okbtn">Accepter aftalen nu</span>
            <span slot="cancelbtn">Accepter senere</span>
            <span slot="footer"></span>
        </modal> 
    <div class="row">
        <HangingView />
        <UnboundView />
        <DupsView />
    </div>
</div>
</template>
<script>
import HangingView from "./HangingView";
import UnboundView from "./UnboundView";
import DupsView from "./DupsView";
import {mapActions} from "vuex";
import {mapGetters} from "vuex";
//import Axios from "axios";
import modal from "./Modal";
export default {
    components : {
        HangingView,
        UnboundView,
        DupsView,
        modal
    },
    data: function () {
        return {                        
            showGDPRModal: false,
            dpaName: "",
            dpaCvr: "",
            dpaEmail: "",
            dpaAddress: "",
            dpaConsent:""
        }
    },
    methods: {
        ...mapActions(["loadHangingOrders","loadUnboundSkus","loadDuplicates","giveConsent","getConsent","muteConsent"]),

        async MuteConsent() {
            await this.muteConsent();
            this.showGDPRModal=false;
        },
        async GiveConsent () {
            let me = this
            let consentGiven = await this.giveConsent({
                    Cvr: this.dpaCvr,
                    Email: this.dpaEmail,
                    Name: this.dpaName,
                    Consent: true,
                    Address: this.dpaAddress
                });            
            if(consentGiven) {
                me.showGDPRModal=false;
                console.log('closing consent form');
            }
            /*
            Axios.post(`${this.theBaseUrl}/api/account/consent/`,
                {
                    Cvr: this.dpaCvr,
                    Email: this.dpaEmail,
                    Name: this.dpaName,
                    Consent: true,
                    Address: this.dpaAddress
                }
            ).then(r => {
                console.log("consentdata:",r.data);
                if (r.data == true) {
                    console.log('consent given, closing form');
                    me.showGDPRModal = false;
                }
            });*/

        },
        scrollTo: function (refName) {
            console.log(refName)
            switch(refName)
            {
                case 'hangingV':
                    console.log(refName);
                    this.$router.push('/dash/hanging');
                    window.scroll(0, this.findPos(document.getElementById(refName)));
                    break;
                case 'unboundV':
                    console.log(refName);
                    this.$router.push('/dash/unbound');
                    window.scroll(0, this.findPos(document.getElementById(refName)));
                    break;
                case 'dupsV':
                    console.log(refName);
                    this.$router.push('/dash/duplicates');
                    window.scroll(0, this.findPos(document.getElementById(refName)));
                    break;
            }
           
        },
        findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
          obj = obj.offsetParent
        } while (obj);
        return [curtop];
        }
}
        
    },
    computed: {
        ...mapGetters(["theBaseUrl","isLoaded","allHanging","allDuplicates","allUnboundSkus","hasConsent","askConsent"]),
        loadingHanging(){
            return !this.isLoaded.includes("hanging");
        },
        loadingUnbound(){
            return !this.isLoaded.includes("unboundskus");
        },
        loadingDuplicates(){
            return !this.isLoaded.includes("duplicates");
        },
        hangingCnt(){
            return this.allHanging.length;
        },
        unboundCnt(){
            return this.allUnboundSkus.length;
        },
        dupsCnt(){
            return this.allDuplicates.length;
        }

    },
    async mounted(){
        console.log("Consent data", this.hasConsent, this.askConsent);
        if(!this.hasConsent && this.askConsent){
            await this.getConsent();            
            if(this.hasConsent ==false) {
                this.showGDPRModal=true;
            }
        }
        /*Axios.get(`${this.theBaseUrl}/api/account/consent/`).then(r => {
            console.log(r.data);
            if (r.data === true) {
                console.log('consent given');
                this.showGDPRModal = false;
            }
            else if (r.data== false) {
                console.log('consent missing')
                this.showGDPRModal = true;
            }

        });*/
    },
    async created() {
        console.log("dash created");
        if(!this.isLoaded.includes("unboundskus")) 
            this.loadUnboundSkus();
        if(!this.isLoaded.includes("hanging")) 
            this.loadHangingOrders();
        if(!this.isLoaded.includes("duplicates")) 
            this.loadDuplicates();        
    }
    
}
</script>
<style>
    .dash-card td:last-child, .dash-card tr,.dash-card th:last-child,.dash-card table {
        border-right: none!important;
    }

    .dash-card td:first-child,.dash-card tr,.dash-card th:first-child,.dash-card table {
        border-left: none!important;
    }

</style>