import { render, staticRenderFns } from "./HangingOrderTotalsView.vue?vue&type=template&id=5471bdf4&"
import script from "./HangingOrderTotalsView.vue?vue&type=script&lang=js&"
export * from "./HangingOrderTotalsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports