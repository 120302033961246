import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
//import { LayoutPlugin } from "bootstrap-vue";

Vue.use(Vuex);

const baseUrl ="";
//const baseUrl ="http://localhost:5002";
//const baseUrl ="http://controller.dansk-e-logistik.dk";
const authUrl =`${baseUrl}/jwt`;
export default new Vuex.Store ({
    strict: false,
    state: {
        products: [],
        accounts:[],
        orders:[],
        hangingorders:[],
        hangingOrderLines:[],
        duplicates:[],
        unboundSkus:[],
        bindingProducts:[],
        bindingSkus:[],
        currentAccount:null,        
        token:null,
        accountId:0,        
        logInStatus:{isLoggedIn:false,status:0,statusText:"not set",data:"not set"},
        inventoryTransactions:[],
        loaded:[],
        baseUrl:baseUrl,
        pwReset:false,
        decodedToken:null,
        appParameters:null,
        canManualOrders:false,
        canManualProducts:false,
        canManualProductVariants:false,
        manualOrderSettings:null,
        manualorders:[],
        ordertemplates:[],        
        manualproducts:[],  
        manualordershippingforms:[],
        manualordershippingcountries:[],  
        consent:false,
        askConsent:true,
    },
    getters: {
        allOrders: state => state.orders,
        allProducts: state => state.products,
        LoggedIn: state => state.logInStatus.isLoggedIn,
        LogInStatus: state => state.logInStatus,
        CurrentAccount: state => state.currentAccount,
        allAccounts: state => state.accounts,
        allHanging: state => state.hangingorders,
        allHangingOrderLines: state => state.hangingOrderLines,
        allDuplicates: state => state.duplicates,
        allUnboundSkus: state => state.unboundSkus,
        allBindingProducts: state => state.bindingProducts,
        allBindingSkus: state => state.bindingSkus,
        allInventoryTransactions: state => state.inventoryTransactions,
        isLoaded: state => state.loaded,
        theBaseUrl: state => state.baseUrl, 
        passwordReset: state => state.pwReset,
        userPermission: state =>state.decodedToken.permission,
        appParameters:state=>state.appParameters,
        canManualOrders:state => state.canManualOrders,
        canManualProducts:state=>state.canManualProducts, 
        canManualProductVariants:state=>state.canManualProductVariants,
        manualOrderSettings: state=>state.manualOrderSettings,
        manualOrders:state=>state.manualorders,
        //manualOrdersDetach:state=> JSON.parse(JSON.stringify(state.manualorders)), 
        //above: a smart way to deep copy an array, in order to detact completely from the datastore
        manualProducts:state=>state.manualproducts,
        manualProductsToDelete(state){
            let r = state.manualproducts.filter(x =>{
                let p = state.products.find(p=>p.ean===x.eanRef);
                if(p!=undefined){ return true}
            });
            return r;
        },
        manualOrdersToDelete(state){            
            let r = state.manualorders.filter(x =>{
                let o = state.orders.find(o=>o.orderNumber===x.orderNumber && (o.status=="Cancelled" || o.status=="Shipped complete"));
                if(o!=undefined){ return true}
            });
            return r;
        },
        loginExpires(state){
            return state.decodedToken.exp;
        }, 
        manualOrderShippingforms:state=>state.manualordershippingforms,
        orderTemplates:state=>state.ordertemplates,
        manualOrderShippingCountries:state=>state.manualordershippingcountries,
        hasConsent:state=>state.consent,
        askConsent:state=>state.askConsent

        
    },    
    mutations: {        
        changeLoadingStatus(state,data){
            if(data.remove){
                let idx = state.loaded.indexOf(data.name);
                while(idx > -1){
                    state.loaded.splice(idx,1)
                    idx = state.loaded.indexOf(data.name);
                }
            } else {
                if(!state.loaded.includes(data.name)){
                    state.loaded.push(data.name)
                }
            }
        },
        clearLoadingStatus(state){
            state.loaded.splice(0,state.loaded.length);
            
        },
        pwReset(state,data){
            state.pwReset = data;
        },
        setProductData(state,pdata) {
            state.products=pdata;
            if(!state.loaded.includes('products'))
                state.loaded.push('products');
        },
        setOrderData(state,data) {
            state.orders=data;
            if(!state.loaded.includes('orders'))
            state.loaded.push('orders');
        },
        setAuthToken(state,t) {
            state.token=t;            
            //console.log("token is",state.token);
        },
        setLoginStatus(state, data) {
            let isLoggedIn=data.status===200;          
            state.logInStatus.isLoggedIn=isLoggedIn;
            state.logInStatus.status=data.status;
            state.logInStatus.statusText=data.statusText;
            state.logInStatus.data =data.data;
        },
        setCurrentAccount(state, acc){            
            state.currentAccount= acc;
            let perm=state.decodedToken.permission
            if(acc.appParameters!=null && perm=='wctrxtra') {            
                let params = JSON.parse(acc.appParameters)
                state.appParameters=params;
                if(Object.prototype.hasOwnProperty.call(params,'xtrafeatures')){
                    state.canManualOrders =params.xtrafeatures.includes("createorders");
                    state.canManualProducts=params.xtrafeatures.includes("createproducts");
                }
            }
        },
        setConsent(state,data){
            state.consent=data;
            if(data==true) {
                state.askConsent=false;
            }
        },
        setAskConsent(state,data){
            state.askConsent=data;
        },
        setAccounts(state, accData){
            state.accounts = accData;
            if(accData.length==1){
                state.currentAccount= accData[0];
                let perm=state.decodedToken.permission
                if(accData[0].appParameters!=null && perm=='wctrxtra') {            
                    let params = JSON.parse(accData[0].appParameters)
                    state.appParameters=params;
                    if(Object.prototype.hasOwnProperty.call(params,'xtrafeatures')){
                        state.canManualOrders =params.xtrafeatures.includes("createorders");
                        state.canManualProducts=params.xtrafeatures.includes("createproducts");
                    }
                }
            }
            state.loaded.push('accounts');
        },
        addToOrdersResultList: function (state, obj) {
            state.orders.push(obj);
        },
        setHangingOrders(state, data) {
            state.hangingorders = data;
            if(!state.loaded.includes('hanging'))
            state.loaded.push('hanging');
        },
        setHangingOrderLines(state, data) {
            state.hangingOrderLines = data;
            if(!state.loaded.includes('hanginglines'))
            state.loaded.push('hanginglines');
        },

        setDuplicates(state, data) {
            state.duplicates = data;
            if(!state.loaded.includes('duplicates'))
            state.loaded.push('duplicates');
        },
        setUnboundSkus(state, data){
            state.unboundSkus=data;
            if(!state.loaded.includes('unboundskus'))
            state.loaded.push('unboundskus');
        },
        setBindingProducts(state,data){
            state.bindingProducts = data;
            if(!state.loaded.includes('bindingproducts'))
            state.loaded.push('bindingproducts');
        },
        setBindingSkus(state,data){
            state.bindingSkus = data;
            if(!state.loaded.includes('bindingskus'))
            state.loaded.push('bindingskus');
        },
        setInventoryTransactions(state,data) {
            state.inventoryTransactions = data;
            if(!state.loaded.includes('inventory'))
            state.loaded.push('inventory');
        },
        setDecodedToken(state,data){
            state.decodedToken=data;
        },
        setManualOrderSettings(state,data) {
            state.manualOrderSettings=data;
            state.loaded.push('manualordersettings');
        },
        setManualOrders(state,data){
            //We need to change the manual orders a bit:
            //adding a Selected indicator field and parsing the orderData json to an addressable object
            //data.map(m=>{m.orderData = JSON.parse(m.orderData); m.Selected=false; return m});
            state.manualorders=data;
            state.loaded.push('manualorders');
        },
        setOrderTemplates(state,data){
            state.ordertemplates=data;
            state.loaded.push('ordertemplates');
        },
        setManualOrderShippingforms(state,data){
            state.manualordershippingforms = data;
            state.loaded.push('manualordershippingforms');          
        },
        setManualOrderShippingCountries(state,data){
            state.manualordershippingcountries = data;
            state.loaded.push('manualordershippingcountries');          
        },

        setManualProducts(state,data){
            //We need to change the manual orders a bit:
            //adding a Selected indicator field and parsing the orderData json to an addressable object
            //data.map(m=>{m.orderData = JSON.parse(m.orderData); m.Selected=false; return m});
            state.manualproducts=data;
            state.loaded.push('manualproducts');
        },
        clearManualOrders(state,deleted){
            state.manualorders = state.manualorders.filter(x=>{
                if(deleted.find(o=>o.orderNumber===x.orderNumber)==undefined){
                    return true;
                }
                return false
            })
        },
        clearManualProducts(state,deleted){
            state.manualproducts = state.manualproducts.filter(x=>{
                if(deleted.find(o=>o.eanRef===x.eanRef)==undefined){
                    return true;
                }
                return false
            })
        },
        setManualOrderSelect(state,data) {
            let om = state.manualorders.find(o=>o.orderNumber==data)
            om.Selected=!om.Selected
        },
        setManualOrdersSelect(state) {
            //not used just for testing the vuex reactivity            
            let v=[...state.manualorders.map(o=>{o.Selected =!o.Selected; return o})];            
            Vue.set(state,'manualorders',v);
            state.manualorders.reverse()
        },
        setManualOrderStatus(state,data){
            
            let o=state.manualorders.find(o=>o.orderNumber===data.orderNumber);
            o.orderSTatus=data.status;
        },
        setManualProductStatus(state,data){
            let p = state.manualproducts.find(p=>p.eanRef===data.eanRef);
            p.status=data.status;
        }
    },
    actions: {/**/
        async setAccount(context,a){
            context.commit("setCurrentAccount",a);
        },
        async muteConsent(context) {
            context.commit("setAskConsent",false);
        },
        async setHttpIntercepters(context){
            console.log("sethttp headers called");
            Axios.interceptors.request.use(
                config => {
                    
                    if(context.state.token != null){
                        //console.log("setting auth header",context.state.token);
                        config.headers.common["Authorization"] =`Bearer ${context.state.token}`; 
                    }
                    if(context.state.currentAccount){
                        //console.log("setting account id header",context.state.currentAccount.accountIdHash);
                        config.headers.common["account-id"] =context.state.currentAccount.accountIdHash;
                    }
                    return config;
                }
            );
            Axios.interceptors.response.use(
                function(response){return response},
                function(error){
                    
                    
                    //let path =new URL(error.response.config.url).pathname;
                    let path =error.response.config.url;
                    console.log("error path is:",path)
                    console.log(error);
                                                            
                    if(401 === error.response.status && path !="/jwt"){ 
                        window.onbeforeunload = null;                       
                        window.location="/";
                        window.location.reload(true);
                    }
                    else {
                        return Promise.reject(error);
                    }
                }
                );

        },
        async refresh(context,scope){            
            if(scope==='all'){
                context.dispatch('setUnloaded','products');
                context.dispatch('setUnloaded','orders');
                context.dispatch('setUnloaded','hanging');
                context.dispatch('setUnloaded','duplicates');                
                context.dispatch('setUnloaded','manualorders');
                context.dispatch('setUnloaded','manualordersettings');
                context.dispatch('setUnloaded','manualproducts');
                context.dispatch('setUnloaded','ordertemplates'); 
                context.dispatch('setUnloaded','unboundskus');
                context.dispatch('setUnloaded','bindingskus');
                context.dispatch('setUnloaded','bindingproducts');
                context.dispatch('loadOrderData');
                context.dispatch('loadProductData');
                context.dispatch('loadUnboundSkus');
                context.dispatch('loadHangingOrders');
                context.dispatch('loadDuplicates');
                context.dispatch('loadManualOrders');
                context.dispatch('loadManualProducts');
                context.dispatch('loadOrderTemplates');
                context.dispatch('loadManualOrderSettings');
                context.dispatch('loadBindingSkus');
                context.dispatch('loadBindingProducts');
               
                


             }
             if(scope=='ordertemplates'){
                context.dispatch('setUnloaded','ordertemplates');
                context.dispatch('loadOrderTemplates')  
             }
             if(scope=='manualorders'){
                await context.dispatch('setUnloaded','manualorders');
                await context.dispatch('loadManualOrders');              
             }
             if(scope=='manualordersettings'){
                context.dispatch('setUnloaded','manualordersettings');
                context.dispatch('loadManualOrderSettings');              
             }
             if(scope=='manualproducts'){
                context.dispatch('setUnloaded','manualproducts');
                context.dispatch('loadManualProducts');              
             }
             if(scope==='orders'){
                await context.dispatch('setUnloaded','orders');
                await context.dispatch('loadOrderData');              
             }
             if(scope==='products'){
                context.dispatch('setUnloaded','products');
                context.dispatch('loadProductData');
             }
             if(scope==='unbound'){
                context.dispatch('setUnloaded','unboundskus');
                context.dispatch('loadUnboundSkus');
             }
             if(scope==='hanging'){
                context.dispatch('setUnloaded','hanging');
                context.dispatch('loadHangingOrders');
             }
             if(scope==='duplicates'){
                context.dispatch('setUnloaded','duplicates');
                context.dispatch('loadDuplicates');                
             }
             if(scope==='dash'){                
                context.dispatch('setUnloaded','hanging');
                context.dispatch('setUnloaded','unboundskus');
                context.dispatch('setUnloaded','duplicates');
                context.dispatch('loadHangingOrders');
                context.dispatch('loadUnboundSkus');
                context.dispatch('loadDuplicates');                
             }

        },
        async loadAccounts(context){            
            let url =`${baseUrl}/api/account`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setAccounts",r.data);               
                }
            }
            catch (err){
                console.log(err);
            }                        
        },
        async getConsent(context) {
            let url =`${baseUrl}/api/account/consent/`;
            try {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setConsent",r.data);
                    console.log('consent set:',r.data)

                }
            }
            catch(err) {
                console.log("error occurred getting consent value",err)    
            }            
        },
        async giveConsent(context,data) {
            let url =`${baseUrl}/api/account/consent/`;
            try {
                let r = await Axios.post(url,data);
                
                if(r.status==200 && r.data==true){
                    console.log("consent given")
                    context.commit("setConsent",true)
                    return true;
                }
                return false;
            }
            catch(err) {
                console.log("error occurred giving consent",err)
            }
        },
        async login(context,credentials) {            
            let formBody = new FormData();
            formBody.append('UserName',credentials.userEmail);
            formBody.append('Password',credentials.userPass);
            try{
                let r = await Axios.post(authUrl,formBody);
                //console.log("full response object", typeof r)                
                if(typeof r !=="undefined" && r.status===200){
                    context.commit("setLoginStatus",{status:r.status,statusText:r.statusText});
                    //console.log("Success - response from login:", r.data);
                    context.commit("setAuthToken",r.data.access_token); 
                    let t = atob(r.data.access_token.split(".")[1])
                    context.commit("setDecodedToken",JSON.parse(t)); 
                }else {
                    //console.log("Login failed - response from login:", r);
                    context.commit("setLoginStatus",{status:401,statusText:"Login failed",data:""});
                }    
            }
            catch(err){

                if(err.response != undefined && err.response.status != undefined) {
                    let r = err.response
                    console.log("catch setLoginStatus",r.status,r.statusText);
                    context.commit("setLoginStatus",{status:r.status,statusText:r.statusText,data:r.data});
                } else {
                    console.log("Error", err.response);
                    console.log("Login failed");
                }
                
            }
            
        },
        async resetPW(context, data) {
            let url =`${baseUrl}/api/user/reset`;
            try {
                let r = await Axios.post(url,{email:data.Email});
                if(r.status==200){
                    context.commit("pwReset",true);
                }
            } 
            catch (err) {
                console.log(err)
            }
        },
        async setPW(context, data) {
            let url =`${baseUrl}/api/user/setpw`;
            try {
                let r = await Axios.post(url,{token:data.token,password:data.password});
                console.log("result from axios",r);
                if(r.status==200){
                    return true;
                } else {
                    return false;
                }
            } 
            catch (err) {
                console.log(err)
                return false;
            }
        },
        async loadOrderTemplates(context){
            let url =`${baseUrl}/api/manual/templates`;
            try {
                let r = await Axios.get(url)
                if(r.status==200){
                    console.log("loaded order templates")
                    context.commit("setOrderTemplates",r.data)
                }
            }
            catch(err){
                console.log(err);
            }
        },
        async loadManualOrderSettings(context) {
            let url =`${baseUrl}/api/manual/settings`;
            try{
                let r = await Axios.get(url);
                if(r.status==200){
                    console.log("loaded manual order settings");
                    context.commit("setManualOrderSettings",r.data)
                }

            }
            catch(err) {
                console.log(err);
            }

        },
        async loadManualOrders(context){
            let url =`${baseUrl}/api/manual/orders`;
            try {
                let r = await Axios.get(url)
                if(r.status==200){
                    console.log("loaded manual orders")
                    context.commit("setManualOrders",r.data)
                }
            }
            catch(err){
                console.log(err);
            }
        },
        async loadManualOrderShippingforms(context){
            let url =`${baseUrl}/api/manual/orders/shippingforms`;
            try {
                let r = await Axios.get(url)
                if(r.status==200){
                    console.log("loaded manual order shippingforms")
                    context.commit("setManualOrderShippingforms",r.data)
                }
            }
            catch(err){
                console.log(err);
            }
        },
        async loadManualOrderShippingCountries(context){
            let url =`${baseUrl}/api/manual/orders/shippingcountries`;
            try {
                let r = await Axios.get(url)
                if(r.status==200){
                    console.log("loaded manual order shipping countries")
                    context.commit("setManualOrderShippingCountries",r.data)
                }
            }
            catch(err){
                console.log(err);
            }
        },
        async loadManualProducts(context){
            let url =`${baseUrl}/api/manual/products`;
            try {
                let r = await Axios.get(url)
                if(r.status==200){
                    console.log("loaded manual products")
                    context.commit("setManualProducts",r.data)
                }
            }
            catch(err){
                console.log(err);
            }
        },
        async saveManualProduct(context,manualProduct){
            let url =`${baseUrl}/api/manual/products`;
            try {
                let r = await Axios.post(url,manualProduct)
                if(r.status==200){
                    console.log("saved manual product") 
                    await context.dispatch('refresh','manualproducts')                   
                }
            }
            catch(err){
                console.log(err);
            }
        },
        async updateManualProduct(context,manualProduct){
            let eanref = encodeURIComponent(manualProduct.eanRef)
            let url =`${baseUrl}/api/manual/products/${eanref}`;
            try {
                let r = await Axios.put(url,manualProduct)
                if(r.status==200){
                    console.log("updated manual product") 
                    await context.dispatch('refresh','manualproducts')                   
                }
            }
            catch(err){
                console.log(err);
            }
        },
        async sendManualProduct(context,eanRef){            
            try {
                context.commit('setManualProductStatus',{eanRef:eanRef,status:2})   
                let product = context.state.manualproducts.find(p=>p.eanRef===eanRef);
                await context.dispatch('updateManualProduct',product);         
            }
            catch(err){
                console.log(err);
            }
        },
        async deleteManualProduct(context,eanRef){
            let eanref = encodeURIComponent(eanRef)
            let url =`${baseUrl}/api/manual/products/${eanref}`;
            try {
                let r = await Axios.delete(url)
                if(r.status==200){
                    console.log("deleted manual product") 
                    await context.dispatch('refresh','manualproducts')                   
                }
            }
            catch(err){
                console.log(err);
            }
        },
        async batchDeleteManualOrders(context,orderNumbers){
            let url =`${baseUrl}/api/manual/orders/batch/delete`
            try {
                let r = await Axios.post(url,orderNumbers)
                if(r.status==200){
                    console.log("deleted manual order batch")                    
                }
            } catch(err) {
                console.log(err)
            }
        },
        async batchDeleteManualProducts(context,eanRefs){
            let url =`${baseUrl}/api/manual/products/batch/delete`
            try {
                let r = await Axios.post(url,eanRefs)
                if(r.status==200){
                    console.log("deleted manual product batch")                    
                }
            } catch(err) {
                console.log(err)
            }
        },
        async deleteManualOrder(context,orderNumber){
            let onum = encodeURIComponent(orderNumber)
            let url =`${baseUrl}/api/manual/orders/${onum}`
            try {
                let r = await Axios.delete(url)
                if(r.status==200){
                    console.log("deleted manual order")
                    await context.dispatch('refresh','manualorders');
                }
            } catch(err) {
                console.log(err)
            }
        },
        async sendManualOrder(context,orderNumber){
            try {
                context.commit('setManualOrderStatus',{orderNumber:orderNumber,status:2})
                let order = context.state.manualorders.find(o=>o.orderNumber===orderNumber);
                await context.dispatch('saveManualOrder',{manualOrder:order,isUpdate:true});
    
            } catch(err) {
                console.log(err)
            }
        },
        async cancelManualOrder(context,orderNumber){
            try {
                context.commit('setManualOrderStatus',{orderNumber:orderNumber,status:6})
                let order = context.state.manualorders.find(o=>o.orderNumber===orderNumber);
                await context.dispatch('saveManualOrder',{manualOrder:order,isUpdate:true});
    
            } catch(err) {
                console.log(err)
            }
        },
        async saveOrderTemplate(context,data){
            let tname = encodeURIComponent(data.templateName)   
            let url =`${baseUrl}/api/manual/templates/${tname}`  
            try {
                let r = await Axios.post(url,data)
                if(r.status==200){
                    console.log("created order template")
                    await context.dispatch('refresh','ordertemplates')
                }
            } catch(err){
                console.log(err)
            }        
        },
        async updateOrderTemplate(context,data){
            let tname = encodeURIComponent(data.templateName)   
            let url =`${baseUrl}/api/manual/templates/${tname}`  
            try {
                let r = await Axios.put(url,data)
                if(r.status==200){
                    console.log("updated order template")
                    await context.dispatch('refresh','ordertemplates')
                }
            } catch(err){
                console.log(err)
            }        
        },
        async deleteOrderTemplate(context,data){
            let tname = encodeURIComponent(data)   
            let url =`${baseUrl}/api/manual/templates/${tname}`  
            try {
                let r = await Axios.delete(url)
                if(r.status==200){
                    console.log("deleted order template")
                    await context.dispatch('refresh','ordertemplates')
                }
            } catch(err){
                console.log(err)
            }        
        },
        async saveManualOrder(context,data){
            let manualOrder=data.manualOrder
            let isUpdate = data.isUpdate
            let onum = encodeURIComponent(manualOrder.orderNumber)            
            let url =`${baseUrl}/api/manual/orders/${onum}`            
            try{
                if(isUpdate==true) {
                    console.log('this is an update',isUpdate,manualOrder)
                    let r = await Axios.put(url,manualOrder)
                    if(r.status==200){
                        console.log("updated manual order")
                        await context.dispatch('refresh','manualorders')
                    }
                } else {
                    console.log('this is an insert',isUpdate)
                    let r = await Axios.post(url,manualOrder)
                    if(r.status==200){
                        console.log("saved manual order")
                        await context.dispatch('refresh','manualorders')
                    }
                }
            }
            catch(err){
                console.log(err)
            }
        },
        async clearOutManualOrders(context){
            
            if(this.getters.canManualOrders){
                
                if(!this.getters.isLoaded.includes("orders")){                   
                    await context.dispatch('refresh','orders')            
                }

                if(!this.getters.isLoaded.includes("manualorders")) 
                {
                    await context.dispatch('refresh','manualorders')
                }  

                let d =this.getters.manualOrdersToDelete
                if(d.length > 0){ 
                    console.log("clear out any obsolete orders")                                                         
                    let onumbs= d.map(o=>o.orderNumber)
                    await context.dispatch('batchDeleteManualOrders',onumbs)
                    context.commit("clearManualOrders",d)
                } 
            }
        },
        async loadOrderData(context){
            let url =`${baseUrl}/api/orders`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setOrderData",r.data);              
                    //await context.dispatch('clearOutManualOrders')
                    
                }
            }
            catch (err){
                console.log(err);
            }  
        },
        async loadHangingOrders(context){
            let url = `${baseUrl}/api/orders/hanging`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setHangingOrders",r.data);
                }
            }
            catch (err){
                console.log(err);
            }
            

            
        },
        async loadHangingOrderLines(context){
            let url = `${baseUrl}/api/orders/hanging/lines`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setHangingOrderLines",r.data);           
                }
            }
            catch (err){
                console.log(err);
            }                          
        },

        async loadDuplicates(context){
            let url = `${baseUrl}/api/catalog/duplicates`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setDuplicates",r.data);        
                }
            }
            catch (err){
                console.log(err);
            }                        
        },
        async loadUnboundSkus(context){
            let url = `${baseUrl}/api/binding/skus/unbound`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setUnboundSkus",r.data);
                }
            }
            catch (err){
                console.log(err);
            }
        },
        async loadBindingProducts(context){
            let url = `${baseUrl}/api/binding/products`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setBindingProducts",r.data);
                }
            }
            catch (err){
                console.log(err);
            }
        },
        async loadBindingSkus(context){
            let url = `${baseUrl}/api/binding/skus`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setBindingSkus",r.data);
                }
            }
            catch (err){
                console.log(err);
            }
        },
        async clearOutManualProducts(context) {
            if(this.getters.canManualProducts){
                        
                let d =this.getters.manualProductsToDelete
                if(d.length > 0){ 
                console.log("clear out any obsolete manualproducts")
                if(!this.getters.isLoaded.includes("manualproducts")){                        
                    await context.dispatch('refresh','manualproducts')
                }
                
                let eans= d.map(o=>o.eanRef)
                await context.dispatch('batchDeleteManualProducts',eans)
                context.commit("clearManualProducts",d)
                }
            }   
        },
        async loadProductData(context) {
            let url =`${baseUrl}/api/catalog`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    context.commit("setProductData",r.data);
                    await context.dispatch('clearOutManualProducts')                                 
                }
            }
            catch (err){
                console.log(err);
            }            
        },
        async loadInventoryTransactions(context,qparams){
            //remove loading indication if exists
            let p = {
                params:qparams
            }
            //let qParams = new URLSearchParams();            
            console.log("Inventory parameters", p);            
            let url =`${baseUrl}/api/inventory`;
            try
            {
                let r = await Axios.get(url,p)
                if(r.status==200){
                    context.commit('setInventoryTransactions',r.data);
                }
            }
            catch (err){
                console.log(err);
            }            
        },
        async setUnloaded(context,name){
            context.commit("changeLoadingStatus",{name:name,remove:true})
        },
        async loadOrderByNumber(context,orderNumber){
            let num = encodeURIComponent(orderNumber)
            let url =`${baseUrl}/api/orders/${num}`;
            try
            {
                let r = await Axios.get(url)
                if(r.status==200){
                    if(r.data.length > 0)
                        context.commit('addToOrdersResultList', r.data[0]);
                }
            }
            catch (err){
                console.log(err);
            }  
        }
    }
});