<template>

<div class="row">
    <div class="col"> 
        <form>
            <div class="form-group form-horizontal">
                <label class="font-weight-bold" for="findref">Search</label>
                <input type="text" id="findref" v-model="search" class="form-control" placeholder="Filter by for product name, SKU or EAN/Reference" />
            </div>
        </form>
    <div class="card card-defult" v-if="loading">
        <div class="card-header text-center"><b>Product catalog</b></div>
        <div class="card-body">
            <div class="text-center">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                </div>
        </div>
    </div>
        <div class="datacard card border-1" v-else>
            <div class="card-header text-white text-center">Product catalog</div>
            <div class="datacard card-body table-responsive mb-0">
            <table class="table table-striped table-bordered table-hover mb-0">
                <thead class="">
                    <tr>
                        <th></th>
                        <th>Handled by DEL</th>
                        <th>EAN/Reference</th>
                        <th>SKU</th>
                        <th>Product Name</th>
                        <th>Variant name</th>
                        <th>Disable Sync</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="(p,idx) in filteredProducts" v-bind:key="p.productVariantId" v-bind:class="{'dimmed-row':p.delHandling==false}">
                    <td><ElipsisButton v-if="p.delHandling" v-on:menuaction="onMenuAction" v-bind:itemnr="idx" v-bind:items="getMenuItems(p)" v-bind:id="p.productVariantId" /></td>
                    <td>{{p.delHandling==true ? 'Yes': 'No'}}</td>
                    <td>{{p.ean}}</td>
                    <td>{{p.sku}}</td>
                    <td>{{p.productName}}</td>
                    <td>{{p.productVariantName}}</td>
                    <td>{{p.syncDisabled ? 'Yes':'No'}}</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="7" style="height:250px"></td>
                    </tr>
                </tfoot>
            </table>
            </div>
        </div>
    </div>
     <modal v-if="showDeleteModal" @close="showDeleteModal = false" @ok="deleteProduct()" hasOk="true" hasCancel="true" isWarning="true">
        <div slot="body">
            Are you sure you want to delete this product? The changes you make cannot be undone.
        </div>
        <h3 slot="header">Delete product ?</h3>
        <span slot="footer"></span>
    </modal>    
    <modal v-if="showDelHandlingModal" @close="showDelHandlingModal = false" @ok="setUnhandled()" hasOk="true" hasCancel="true"  isWarning="true">
        <div slot="body">
            Are you sure you want to disable handling of this product? The changes you make cannot be undone. When handling is disabled, the products wil be ignored on orders and will not be shipped.
        </div>
        <h3 slot="header">Remove handling ?</h3>
        <span slot="footer"></span>
    </modal>
    <modal v-if="showSyncModal" @close="showSyncModal = false" @ok="disableSync()" hasOk="true" hasCancel="true"  isWarning="true">
        <div slot="body">
            Are you sure you want to disable sync for this product? The changes you make cannot be undone. When sync is disables you will no longer receive stock transactions on this product.
        </div>
        <h3 slot="header">Disable sync ?</h3>
        <span slot="footer"></span>
    </modal>
    <modal v-if="showDeleteErrorModal" @ok="showDeleteErrorModal = false" hasOk="true" isWarning="true">
            <div slot="body">
                {{ errorText }}
            </div>
            <h3 slot="header">An error occurred</h3>
            <span slot="footer"></span>
        </modal>
    </div>

</template>

<script>
import ElipsisButton from "./ElipsisButton"
import Axios from "axios"
import {mapGetters,mapActions} from "vuex";
import modal from "./Modal"
export default {
    data:function(){
        return {            
            search:undefined,
            showDeleteModal:false,
            showDelHandlingModal:false,
            showSyncModal:false,
            showDeleteErrorModal:false,
            actionId:null,
            errorText:""        
        }
    },
    methods:{        
        ...mapActions(["loadProductData","refresh"]),
        getMenuItems(p){
            let itms = [];
            if(!p.sku){
                itms.push(
                    {link:"#",name:"Bind", action:"bind"},                
                )
            } else {
                itms.push(
                    {link:"#",name:"Change binding", action:"bind"},                
                )
            }
            if(!p.syncDisabled){
                itms.push({link:"#",name:"Stop sync", action:"unsync"},);
            } else {
                itms.push({link:"#",name:"Start sync", action:"sync"},);
            }
            itms.push(
                {link:"#",name:"Stop handling", action:"unhandle",icon:"fa-exclamation-circle"},
                {link:"#",name:"Delete", action:"delete", vclass:"list-group-item-warning" ,icon:"fa-exclamation-circle"}
            );
            return itms;

        },
        onMenuAction(action, id){
            console.log(`perform ${action} on ${id}`);
            this.actionId=id;
            if(action==='bind'){
                this.$router.push({name:"productbindings",params:{id}});
            }
            if(action==='delete'){
                this.showDeleteModal=true;
            }
            if(action==='unhandle'){
                this.showDelHandlingModal=true;
            }
            if(action==='unsync'){
                this.showSyncModal=true;
            }
            if(action==='sync'){
                this.enableSync();
            }
        },
        deleteProduct(){
            console.log("delete",this.actionId);
            let me = this
            Axios.delete(`${this.theBaseUrl}/api/catalog/${me.actionId}`).then(r => {
                //this.$store.dispatch('invalidate', { resources: 'all' });
                me.refresh('products');
                console.log(r.data);
            }).catch(function (reason) {
                console.log("Error deleting reference", reason);
                switch(reason.response.status) {
                    case 404:
                        me.errorText ="The product ean/reference could not be deleted, there may be active orders containing this ean/reference."
                        break;
                    case 403:
                        me.errorText="You do not have sufficient permissions to perform this operation"
                        break;
                    default:
                        me.errorText="An error ocurred, the product ean/reference could not be deleted"
                        break;
                }                                
                me.showDeleteErrorModal = true;
            });
            this.showDeleteModal=false;
        },
        disableSync(){
            let me = this;
            console.log("disabling sync on", this.actionId)
            //let obj = { syncDisabled: 'yes', handledByDel: 'undefined' };            
            Axios.put(`${this.theBaseUrl}/api/catalog/${this.actionId}`,{syncDisabled:'yes'}).then(r => {
                this.refresh('products');                
                console.log(r.data);
            }).catch(function (reason) {
                console.log("Error disabling sync", reason);
                
                switch(reason.response.status) {              
                    case 403:
                        me.errorText="You do not have sufficient permissions to perform this operation"
                        break;
                    default:
                        me.errorText="An error ocurred, the operation could not be performed"
                        break;
                }                                
                me.showDeleteErrorModal = true;
            });

            this.showSyncModal=false;
        },
        enableSync(){
            let me = this;
            console.log("enabling sync", this.actionId)
            //let obj = { syncDisabled: 'yes', handledByDel: 'undefined' };            
            Axios.put(`${this.theBaseUrl}/api/catalog/${this.actionId}`,{syncDisabled:'no'}).then(r => {
                this.refresh('products');                
                console.log(r.data);
            }).catch(function (reason) {
                console.log("Error eanbling sync", reason);
                
                switch(reason.response.status) {              
                    case 403:
                        me.errorText="You do not have sufficient permissions to perform this operation"
                        break;
                    default:
                        me.errorText="An error ocurred, the operation could not be performed"
                        break;
                }                                
                me.showDeleteErrorModal = true;
            });

            this.showSyncModal=false;
        },
        setUnhandled(){
            let me = this;
            console.log("unhandle", this.actionId)
            //let obj ={ syncDisabled: 'undefined', handledByDel: 'no' };
            Axios.put(`${this.theBaseUrl}/api/catalog/${this.actionId}`,{handledByDel:'no'}).then(r => {
                this.refresh('products');                
                console.log(r.data);
            }).catch(function (reason) {
                console.log("Error setting unhandled", reason);
                
                switch(reason.response.status) {              
                    case 403:
                        me.errorText="You do not have sufficient permissions to perform this operation"
                        break;
                    default:
                        me.errorText="An error ocurred, the operation could not be performed"
                        break;
                }                                
                me.showDeleteErrorModal = true;
            });
            this.showDelHandlingModal=false;
        }

    },
    components:{
        ElipsisButton,
        modal
    },
    computed: {
        ...mapGetters(["allProducts","isLoaded","theBaseUrl"]),
        loading(){
            return !this.isLoaded.includes("products");
        },
        products(){
            return this.allProducts
        },
        filteredProducts: function () {
            var plist = this.products.slice(0);
            
            if (this.search) {
                var search = this.search.trim();
                plist = plist.filter(function (row) {
                    if (!row.ean || !row.productName)
                        return false;
                    var b = row.ean.search(new RegExp(search, "i")) >= 0
                        || row.productName.search(new RegExp(search, "i")) >= 0
                        || (row.sku != null && row.sku.search(new RegExp(search, "i")) >= 0)
                        || (row.productVariantName != null && row.productVariantName.search(new RegExp(search, "i")) >= 0);

                    if (b)
                        return true;
                    return false;
                });
            }
            return plist;
        }
    },
    created(){
        
        if(!this.isLoaded.includes("products")){
            
            this.loadProductData();
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}
</script>
<style scoped>
.datacard table .dimmed-row {
    background-color: #d6d6d6;
    color: #adadad;
}
</style>