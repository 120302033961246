<template>
     <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div v-bind:style="cssStyle" class="modal-container container alert alert-warning" v-bind:class="dialogsize" >
                    <div class="modal-header row">
                        <slot name="header">
                            default header
                        </slot>
                    </div>
                    <div class="modal-body row">
                        <slot name="body">
                            default body
                        </slot>
                    </div>
                    <div class="modal-footer row">
                        <slot name="footer">
                            default footer
                        </slot>
                        <div class="controls btn-group">
                            <button v-if="hasOk" v-bind:class="{'btn-info':isInfo,'btn-success':isConscent,'btn-warning':isWarning}" class="btn modal-default-button text-white" @click="$emit('ok')">
                                <slot name="okbtn">OK</slot>
                            </button>
                            <button v-if="hasCancel" v-bind:class="{'btn-warning':isConscent,'btn-secondary':!isConscent}" class="btn modal-default-button text-white" @click="$emit('close')">
                                <slot name="cancelbtn">Cancel</slot>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>    
</template>
<script>
export default {
    props:['hasOk','hasCancel','isInfo','isWarning','isConscent','size','cssStyle'],    
    computed:{
      dialogsize(){
        if(this.size==undefined)
          return 'default'
        else {
          return this.size
        }
      }
    }

}
</script>
<style scoped>
/* modal dialog styles*/
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  
  margin: 0px auto;   
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { 
  .modal-container.large {
    min-width:60%;
    max-width:99%;
  }
 }
 

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) { 
.modal-container.default {
    min-width:25%;
    max-width:60%;
  }
.modal-container.large {
    min-width:60%;
    max-width:95%;
  }
.modal-container.xlarge {
    min-width:60%;
    max-width:95%;
  }
 }

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) { 
  .modal-container.default {
    min-width:25%;
    max-width:50%;
  }
  .modal-container.large {
    min-width:25%;
    max-width:75%;
  }
.modal-container.xlarge {
    min-width:25%;
    max-width:75%;
  }
 }

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) { 
  .modal-container.default {
    min-width:25%;
    max-width:30%;
  }
  .modal-container.large {
    min-width:25%;
    max-width:40%;
  }
  .modal-container.xlarge {
    min-width:25%;
    max-width:60%;
  }
 }


.modal-header h3 {
  margin-top: 0;
  
}

.modal-body {
  
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>