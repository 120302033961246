import Vue from 'vue'
import App from './App.vue'
//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { BootstrapVue} from 'bootstrap-vue'



Vue.config.productionTip = false

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import router from "./router";
import axios from "axios";
Vue.use(BootstrapVue);
//Vue.use(IconsPlugin);
import store from "./store"

new Vue({
  //BootstrapVue,
  //IconsPlugin,
  store,
  router,
  axios,  
  render: h => h(App),
}).$mount('#app')

Vue.directive('click-outside', {  
  bind: function (el, binding, vnode) {
      //console.log("click outside directive binding");
      el.event = function (event) {
          //console.log("el:", el,event.target);
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
              // and if it did, call method provided in attribute value
              vnode.context[binding.expression](event);
          }
          
      };
      //el.event.stopPropagation=true;
      document.body.addEventListener('mouseup', el.event);
      document.body.addEventListener('touchstart', el.event);
  },
  unbind: function (el) {
      document.body.removeEventListener('mouseup', el.event);
      document.body.removeEventListener('touchstart', el.event);
  },
});

