<template>

<div class="col-12 mt-5" v-if="allHanging.length">
    <div class="card card-defult" v-if="loading">
        <div class="card-header"><b>Hanging orders</b></div>
        <div class="card-body">
            <div class="text-center">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="card datacard dash-card" v-else>
        <div id="hangingV"  class="card-header bg-warning border-warning text-white py-2">
            <div class="pull-left mt-1"><b>Hanging orders</b></div>
            <a href="#" v-on:click.prevent="viewTotals()" class="pull-right btn btn-sm btn-outline-light m-0" style="background-color:#fdcb22">View totals</a>
        </div>
        <div class="card-body table-responsive-md p-0 mb-0">
              <table class="table table-striped table-bordered table-hover mb-0">                
                <thead>
                    <tr>
                        <th class=""></th>
                        <th class="">Date Received</th>
                        <th class="">OrderNumber</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="o in allHanging" v-bind:key="o.OrderNumber">
                        <td>
                            <ElipsisButton v-on:menuaction="onMenuAction" v-bind:items="menuitems" v-bind:id="o.orderId" />
                        </td>
                        <td>{{o.dateCreated}}</td>
                        <td>{{o.orderNumber}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
</div>

</template>

<script>
import ElipsisButton from "./ElipsisButton";
import {mapGetters} from "vuex";
export default {
    data:function(){
        return {
            menuitems:[                
                {link:"#",name:"View Order", action:"view"},                
            ],
            search:undefined
        }
    },    
    components:{
        ElipsisButton
    },
    computed: {        
        ...mapGetters(["allHanging","isLoaded"]),
        loading(){
            return !this.isLoaded.includes("hanging");
        }
    },
    methods:{                
         onMenuAction(action, oid){
            //console.log(`perform ${action} on ${id}`); 
             if(action==="view"){
                 this.$router.push({name:"hangingorderlines",params:{id:oid}})
             }
            
        },
        viewTotals(){
            this.$router.push({name:"hangingorderlinetotals"})
        }
    }    
}
</script>