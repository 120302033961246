<template>
    <div>
        
        <div class="alert alert-info" v-if="currentProduct">
            <div class="row">
                <div class="col-md-10">
                    <b>Ean/reference:</b> {{currentProduct.ean}}, <b>Name:</b> {{currentProduct.productName}}
                    <span v-if="currentProduct.productVariantName">
                        , <b>Variant name:</b> {{currentProduct.productVariantName}}
                    </span>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-info pull-right" style="margin-top:-5px" v-on:click="goBack()">Back to catalog</button>
                </div>
            </div>
        </div>
        <div class="datacard card border-1 mb-3">
            <div class="card-header text-white text-center">Currently linked SKU's</div>
            <div class="datacard card-body table-responsive mb-0">
                <table class="table table-striped table-bordered table-hover mb-0">
                    <thead>
                        <tr class="border-bottom">
                            <th colspan="3">
                                <div v-if="isbundle" class="alert alert-danger">Warning! you have more that one SKU on this product, this will create a bundle, as a result both sku's will be sent when ordering this product.<br/>It may also result in a false indication of stock if you sell this SKU independently.<br/> Are you sure this is what you want? <br/>
                                We recommend creating your bundles in your store, to enable immediate stock transactions.
                                </div>
                                <div v-if="isboundsku" class="alert alert-danger">
                                    Warning! There seems to be another product attached to the SKU you selected.<br /> 
                                    This may result in a false indication of stock, if you sell this SKU independently<br /> Are you sure this is what you want?<br />
                                </div>                                
                            </th>
                            <th colspan="1">
                                <button class="btn btn-default pull-right" style="margin-left:6px" v-on:click="goBack()">Cancel</button>
                                <button class="btn btn-warning pull-right" v-on:click="showModal=true">Save bindings</button>
                            </th>
                        </tr>
                        <tr v-if="bindings.length">
                            <th>Remove</th>
                            <th>SKU Number</th>
                            <th>Alternate SKU</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(b, idx) in bindings" v-bind:key="idx">
                            <td><button class="btn btn-default" v-on:click="removeSku(b, idx)"><i class="fa fa-minus" aria-hidden="true"></i></button></td>
                            <td>{{b.skuNumber}}</td>
                            <td>{{b.altSku}}</td>
                            <td>{{b.skuDescription}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <modal v-if="showModal" @close="showModal = false" @ok="saveBindings()" hasOk="true" hasCancel="true" isWarning="true">
            <div slot="body">
                Are you sure you want to save the current bindings ? The changes you make has influence on the actual products being shipped on orders.
            </div>
            <!--
            you can use custom content here to overwrite
            default content
        -->
            <h3 slot="header">Save bindings ?</h3>
            <span slot="footer"></span>
        </modal> 
        <modal v-if="showBindingErrorModal" @ok="showBindingErrorModal = false; showModal=false;" hasOk="true"  isWarning="true">
                <div slot="body">
                    {{errorText}}
                </div>
                <h3 slot="header">An error occurred</h3>
                <span slot="footer"></span>
        </modal>       
    <div class="datacard card border-1">
        <div class="card-header text-white text-center">Available SKU Numbers</div>
        <div class="datacard card-body table-responsive mb-0">
            <table class="table table-striped table-bordered table-hover mb-0">
                <thead>
                    <tr>
                        <th colspan="4" class="border-bottom">
                            <form>
                                <div class="form-group form-horizontal ">
                                        <label for="findref">Filter</label>
                                        <input type="text" id="findref" class="form-control" v-model="search" placeholder="search for sku or description" />
                                </div>
                            </form>
                        </th>
                    </tr>
                    <tr>
                        <th>Select</th>
                        <th>SKU Number</th>
                        <th>Alternate SKU</th>
                        <th>Description</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(s, idx) in filteredSkus" v-bind:key="idx" v-bind:class="{'dimmed-row':s.hasBinding}">
                        <td style="width:50px"><button class="btn btn-default" v-on:click="addSku(s, idx)"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                        <td>{{s.skuNumber}}</td>
                        <td>{{s.altSku}}</td>
                        <td>{{s.skuDescription}}</td>
                    </tr>                    
                </tbody>
            </table>
        </div>
    </div>

    </div>
</template>
<script>
import {mapGetters} from "vuex";
import modal from "./Modal"
import Axios from "axios";
export default {
    props:['id'],
    components:{
        modal
    },
    data: function () {
        return {
            bindings: [],      
            showModal: false,
            search: undefined,
            showBindingErrorModal: false,
            products:[],
            bindingskus:[],
            errorText:""
            
        }
    },
    computed: {
        ...mapGetters(["theBaseUrl","isLoaded","allBindingSkus","allProducts"]),
        isboundsku: function() {
            if(this.bindings.some(x => { return x.hasBinding })) {
                return true;
            }
            return false;
        },
        filteredSkus: function () {
            var skulist = this.bindingskus;
            
            if (this.search) {
                var search = this.search.trim();
                skulist = skulist.filter(function (row) {
                    if (!row.skuNumber)
                        return false;
                    var b1 = row.skuNumber.search(new RegExp(search, "i")) >= 0
                    var b2 = false;
                    if (row.skuDescription)
                        b2 = row.skuDescription.search(new RegExp(search, "i")) >= 0;
                        
                    
                    var b = b1 || b2;
                    if (b)
                        return true;
                    return false;
                });
            }
            return skulist;
        },
        currentProduct: function () {
            var thisid = this.id;
            var s = this.products.filter(function (row) {
                return row.productVariantId == thisid;
            });
            return s[0];
        },
        skustobind: function () {
            return this.bindingskus;
        },
        isbundle: function()
        {
            if (this.bindings.length > 1)
                return true;
            return false;
        },
        loading: function () {
            return !this.isLoaded.includes('bindingskus');
        }
    },
    methods: {
        goBack: function () {
            this.$router.push('/catalog');
        },
        saveBindings: function () {
            var skuNumbers = [];

            for (var i = 0; i < this.bindings.length;i++){
                skuNumbers.push(this.bindings[i].skuNumber);
            }
                          
            console.log('Saving bindings');
            console.log(skuNumbers);
            let me = this;
            Axios.post(`${this.theBaseUrl}/api/binding/products/${this.id}`,skuNumbers).then((r)=>
            {
                console.log(r.data);
                this.showModal = false;
                this.$store.dispatch('loadBindingSkus');
                this.$store.dispatch('loadProductData');
                this.$store.dispatch('loadUnboundSkus');
                 this.$router.push('/catalog');            
            
/*
            this.$http.post('api/binding/products/' + this.id, skuNumbers).then(r=> {
                console.log(r.data);
                this.$store.dispatch('invalidate', { resources: 'products,unboundskus,bindingproducts,bindingskus' });
                this.showModal = false;
                this.$store.dispatch('loadBindingSkus');
                this.$store.dispatch('loadProducts');
                this.$store.dispatch('loadUnboundSkus');
                this.$router.push('/catalog');
*/
            }).catch(function (reason) {
                console.log("Error creating productbinding", reason);
                
                switch(reason.response.status) {              
                    case 404:
                        me.errorText="The binding could not be changed, there may be active orders containing this ean/reference."
                        break;
                    case 403:
                        me.errorText="You do not have sufficient permissions to perform this operation"
                        break;
                    default:
                        me.errorText="An error ocurred, the operation could not be performed"
                        break;
                }                                
                me.showBindingErrorModal = true;
            });
            
           
        },
        isAvailable: function (sku) {
            if (this.bindings.length===0 || this.bindings.some(function (el) {
                
                 return el.skuNumber !== sku.skuNumber;
            })) {
                return true;
            }
            console.log('not rendering ' + sku.skuNumber);
            return false;
        },
        addSku(sku, index)
        {
                            
            this.bindings.push(sku);
            this.skustobind.splice(index, 1);
            console.log('now remove at position:' + index);
        },
        removeSku(sku, index)
        {
            //console.log('remove sku ' + sku.skuNumber + ' index ' + index);
            this.skustobind.push(sku);
            this.bindings.splice(index, 1);
            
        }

        
        
    },
    mounted() {

        if(!this.isLoaded.includes('products')){
            this.$store.dispatch('loadProductData').then(()=>{
                this.products=this.allProducts.slice(0);
            })
        }
        else {
               this.products=this.allProducts.slice(0);
        }
        
            

        if(!this.isLoaded.includes('bindingskus')){
             this.$store.dispatch('loadBindingSkus').then(() => 
                { 
                    this.bindingskus = this.allBindingSkus.slice(0);
                });
        }
        else{
            this.bindingskus = this.allBindingSkus.slice(0);
        }

        Axios.get(`${this.theBaseUrl}/api/binding/products/${this.id}/bindings`).then(r=> {
            this.bindings = r.data;
            // OK, here im a defining a function variable _f, so I dont have to write the same code twice
            var _f = function (_this) {
                for (var i = 0; i < _this.bindings.length; i++) {
                    var b = _this.bindings[i];
                    var r = _this.bindingskus.filter(function (row) {
                        return row.skuNumber == b.skuNumber;

                    });

                    if (r[0]) {
                        b.altSku = r[0].altSKu;
                        b.skuDescription = r[0].skuDescription;
                    }
                }
            }
            
            _f(this)
                         

        });
        window.scrollTo(0, top);

    }
}
</script>
<style scoped>
.datacard table .dimmed-row {
    background-color: #d6d6d6;
    color:#adadad;

}
</style>