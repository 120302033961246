<template>
    <div class="row">
        <div class="col-12">
            <div class=" card">
                <div class="card-header" style="background-color:#6f799e;border: solid 1px #dfdfdf;height:50px">
                    <div class="text-white">Add product <i v-on:click="showHelp=!showHelp"  class="btn fa fa-question-circle fa-xl pull-right  text-white" style="font-size:1.6em;line-height:0.45em"></i></div>
                </div>
                <div class="card-body">
                    
                        <div class="form-row">
                            <div class="form-group col-md-3">
                                <label for="EanRef">EAN/Reference</label>
                                <input v-model="manualProduct.eanRef" type="text" id="EanRef" aria-describedby="EanRefHelp" class="form-control" :class="{'border-danger': formValidate && !manualProduct.eanRef}">
                                <small v-if="showHelp" id="EanRefHelp" class="form-text text-muted">Required - every product and variant must have an unique product reference</small>
                            </div>                                                    
                            <div class="form-group col-md-9">
                                <label for="ProductTitle">Product title</label>
                                <input v-model="manualProduct.productTitle" type="text" id="ProductTitle"  aria-describedby="ProductTitleHelp" class="form-control" :class="{'border-danger': formValidate && !manualProduct.productTitle}">
                                <small v-if="showHelp" id="ProductTitleHelp" class="form-text text-muted">Required - every product must have a title </small>
                            </div>
                        </div>
                        <div v-if="canManualProductVariants" class="row mb-2"><div class="col-12"> <button class="btn btn-default" @click="showVariantInfo=!showVariantInfo">More info <i class="fa" v-bind:class="[showVariantInfo ? 'fa-angle-double-up':'fa-angle-double-down']"></i></button></div></div>
                        <div v-if="showVariantInfo && canManualProductVariants" class="form-row">
                            <div  class="alert alert-warning col-12">
                                OBS! Do not enter Product ID, Variant ID and Title unless absolutely necessary 
                                and only enter Product ID if you have the need to match product ID of another system such as a webshop.<br/>
                                However if Variant info is entered, a product ID must be entered too, and must be the same for alle variants of a product.
                            </div>                            
                            <div class="form-group col-md-3">
                                <label for="ProductId">Product ID</label>
                                <input :disabled="editItem!=undefined" v-model="manualProduct.productID" type="text" id="ProductId"  aria-describedby="ProductIdHelp" class="form-control">
                                <small v-if="showHelp" id="ProductIdHelp" class="form-text text-muted">Optional - You may specify your own productID, if you do not, a productId will be generated</small>
                            </div>    
                            <div class="form-group col-md-3 ">
                                <label for="VariantId">Variant ID</label>
                                <input v-model="manualProduct.productVariantID" type="text" id="VariantId"  aria-describedby="VariantIdHelp" class="form-control">
                                <small v-if="showHelp" id="VariantIdHelp" class="form-text text-muted">Optional - You may enter a variant ID, but if so, also enter a variant title </small>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="VariantTitle">Variant Title</label>
                                <input v-model="manualProduct.productVariantTitle" type="text" id="VariantTitle"  aria-describedby="VariantTitleHelp" class="form-control">
                                <small v-if="showHelp" id="VariantTitleHelp" class="form-text text-muted">Optional - You may enter a variant title, but only if you enter a variant ID </small>
                            </div>
                        </div>
                         <div class="form-row">
                             <div class="form-group col-12">
                             <button @click="saveProduct" class="btn btn-primary float-right">Save product</button>
                             <button @click="cancelEdit" class="btn btn-secondary float-right mr-1">Cancel</button>
                             </div>
                         </div>                    
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions,mapGetters} from "vuex";
export default {
    props:['editItem'],
    data:function(){
        return {
            showHelp:false,
            showVariantInfo:false,
            Text:"product",
            manualProduct:{},
            formValidate:false
        }
    },
    computed:{
        ...mapGetters(["canManualProductVariants"]),
        formValid(){
            if(this.manualProduct.eanRef && this.manualProduct.productTitle)
                return true;
            return false;
        }
    },
    methods:{
        ...mapActions(["saveManualProduct","updateManualProduct"]),
        cancelEdit(){
            this.$router.push({name:"manualproducts"})
        },
        async saveProduct(){
            if(this.formValid){
                console.log("Save product")
                if(this.editItem!=undefined) {
                    await this.updateManualProduct(this.manualProduct);
                } else {
                    await this.saveManualProduct(this.manualProduct);
                }            
                this.$router.push({name:"manualproducts"})
            } else {
                this.formValidate=true;
                this.showHelp=true;
            }

        }
    },
    created(){
            if(this.editItem != undefined) {
                this.manualProduct = JSON.parse(JSON.stringify(this.editItem));
                if(this.editItem.status==2)
                    this.manualProduct.status=3;
            }        
        }
    }

</script>