<template>
    <div :id="id" class="btn elipsis-btn" v-on:click="isvisible=!isvisible" v-click-outside="clickoff" > 
        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
        <div class="elipsis-flyout list-group list-group-flush" v-if="isvisible">                        
                   <a  v-on:click.prevent="$emit('menuaction',i.action,id)" class="list-group-item text-left list-group-item-action" href="#" v-for="i in items" v-bind:key="i.name" v-bind:class="i.vclass">                   
                    {{i.name}}
                    <span v-if="i.icon" v-bind:class="i.icon" class="pull-right m-1 fa-lg fa"></span>
                    </a>                
                <!-- {{id}}-->                        
        </div>
    </div>    
</template>
<script>
export default {    
    props:['items','itemnr','id'],
    data: function() {
        return {
            isvisible:false
        }
    },
    methods: {
        clickoff(e) {   
            //console.log("clickoff called");         
            let t = e.target;
            //console.log("targets",t);
            if (t.id == this.id || t.parentElement.id == this.id)             
                return;                                      
            this.isvisible = false;    
        }
    }
}
</script>

<style>
    .elipsis-btn {
        position:relative;
        padding:0.3rem 0.66rem;
        border-color:#bbb
    }
    .elipsis-btn:hover {
        background-color: #ccc;
        border-color:#bbb;
        color:#fff;
    }
    .elipsis-flyout{
        position:absolute;
        top:24px;
        left:15px;
        z-index:100;
        background-color:#fff;
        min-width:230px;
        box-shadow: rgb(236, 230, 230) 1px 2px 15px;
    }


</style>>

