<template>
<div class="container">
    <div class="row">
    <div class="mx-auto mt-5" id="reset">    
    <!-- @if (!ViewBag.Success){ -->
    <div class="card login-form" style="margin-top:50px;margin-bottom:50px;width:450px;">
        <div class="card-header text-white" style="background-color:#6f799e">
           Enter your new password
        </div>
        <div class="card-body">            
                <fieldset>
                    <div class="form-group form-horizontal">
                        <input class="form-control" placeholder="New password" name="password" :type="pwfield" v-model="password">                        
                    </div>
                    <div class="form-group">
                        <input class="form-control" placeholder="Repeat password" name="repeat" :type="pwfield" v-model="repeat" :class="{'invalid-field':invalidMatch}">
                        <b class="pull-right" v-on:click="showPw()" style="cursor:pointer;margin: 5px 0">Show passwords <i class="fa fa-eye"></i></b>
                    </div>                                        
                    <input type="hidden" name="token" value="@ViewBag.Token" />
                </fieldset>
                
                    <div id="pwstrength" v-bind:class="'strength-' + PwStrength" v-if="password">
                        Password strength: {{PwStrengthText}}
                        <div class="pwinfo"><a href="#" v-on:click.prevent="showPWInfo()"><i class="fa fa-question-circle-o"></i></a></div>
                    </div>
                    
                
                <div style="max-width:150px;margin: 10px auto;text-align:center" >
                    <button class="form-control" v-bind:disabled="buttonDisabled" v-on:click="setNewPassword">Reset password</button>
                </div>            
        </div>
    </div>
    <div class="alert alert-info" v-if="showinfo">
        Your password must contain two lower case letters, two uppercase letters, two special characters, two numbers, and it must be at least 16 characters long.
    </div>
    <!--} -->
    <!--@if (ViewBag.IsPost)
    {
        @if (!string.IsNullOrEmpty(ViewBag.ErrorMsg))
        {-->
        <div v-if="showFailure" class="alert common-alert alert-danger">
            <b>Failed to set your password </b><br/>
            <a href="/">Return to login page</a>
        </div>
         <!--}
        else{-->
            <div v-if="showSuccess" class="well" style="margin-top:50px;">
                <div class="alert common-alert alert-success" style="text-align:center">
                    <b>Your password has been changed</b><br/>
                    <a href="/">Return to login page</a>
                </div>
           </div>
        <!--}
    }--> 
</div>
</div>
</div>
</template>
<script>
import {mapActions} from "vuex";
export default {
    data: function() {
        return {            
            password: '',
            repeat: '',
            showinfo: false,
            pwfield: 'password',
            showSuccess:false,
            showFailure:false
        }
    },
    methods: {
        ...mapActions(["setPW"]),
        async setNewPassword(){
            let model = {password:this.password,token:this.$route.query.token};
            let r =await this.setPW(model)
            console.log("value from set setPW:",r)
            if(r){
                this.showSuccess=true;
            } else {
                this.showFailure=true;
            }
        },   
        showPWInfo () {
            if (this.showinfo)
                this.showinfo = false;
            else
                this.showinfo = true;
        },
        showPw() {
            this.pwfield = 'text';
            var me = this;
            setTimeout(function () { me.pwfield = 'password'; }, 5000);
                        
        },
        hidePw() {
            var me = this;
            setTimeout(function () { me.pwfield = 'password'; }, 500);
        }
    },
    computed: {
        PwStrength() {
            var pwString = this.password;
            var strength = 0;

            strength += /.*[A-Z].*[A-Z].*/.test(pwString) ? 1 : 0;
            strength += /.*[a-z].*[a-z].*/.test(pwString) ? 1 : 0;
            strength += /.*[0-9].*[0-9].*/.test(pwString) ? 1 : 0;
            strength += /.*[\W].*[\W].*/.test(pwString) ? 1 : 0;
            strength += pwString.length > 11 ? 1 : 0;
            strength += pwString.length > 15 ? 1 : 0;
            
            return strength;
            
        },
        PwStrengthText() {
            if (this.PwStrength <=1)
                return 'Very weak';            
            else if (this.PwStrength < 3)
                return 'Weak';
            else if (this.PwStrength < 6)
                return 'Medium';
            else if (this.PwStrength > 5)
                return 'Strong';
            else
                return 'Ridiculous';
        },
        buttonDisabled() {
            
            if (this.password === "" && this.repeat === "")
                return true;

            if (this.invalidMatch)
                return true;

            if (this.PwStrength < 6)
                return true;


            return false;
        },
        invalidMatch() {
            return this.password!==this.repeat;
        }
    }
}
</script>