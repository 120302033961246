<template>
  <div id="app">
    <PasswordResetApp v-if="ResetRequest()" />
    <AccApp v-if="LoggedIn() && !CurrentAccount() && !ResetRequest() " />
    <ControllerApp v-if="LoggedIn() && CurrentAccount() && !ResetRequest() " />
    <LoginApp v-if="!LoggedIn() && !ResetRequest()" />
  </div>

</template>

<script>

//import ControllerApp from "./components/ControllerApp";
import ControllerApp from "./components/ControllerApp";
import LoginApp from "./components/LoginApp";
import AccApp from "./components/AccountSelectorApp";
import PasswordResetApp from './components/PasswordResetApp.vue';
//import PasswordReset from "./components/PasswordReset";
//import {mapGetters,mapActions} from "vuex";
import {mapGetters} from "vuex";
//const { idle, lastActive } = this$useIdle(5 * 60 * 1000) // 5 min


export default {
  name: 'app',
  components: {
    ControllerApp,
    LoginApp,
    AccApp,
    PasswordResetApp
   
    //HelloWorld
  },
  data: function(){
    return {
      
      isauth:true,
      token:""
    }
  },
  methods: {    
    //...mapActions(["login","initData"]),
    ...mapGetters(["LoggedIn","CurrentAccount"]),
    ResetRequest() {
      console.log(this.$route);
      if(this.$route.path=="/login/reset"){
        return true;
      }
      return false;
    }

  },
  mounted(){
    this.$store.dispatch("setHttpIntercepters");

  }
}
</script>

<style>

</style>
