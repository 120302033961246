<template>
<div class="container" v-cloak>
        <div clas="row">
            <div class="mx-auto mt-5" style="max-width:450px" v-cloak>
                <div class="card login-form" >
                <div class="card-header text-white" style="background-color:#6f799e">
                    Please select an account
                </div>
                <div v-if="!loading" class="card-body">
                    <form role="form" autocomplete="on">
                        <fieldset>
                            <div class="form-group">
                                <input class="form-control" placeholder="Filter by name" type="text" autofocus="" v-model="accountFilter">
                            </div>                                                                                    
                        </fieldset>
                    </form>
                    <div>
                        <div class="p-2 border" id="accountlist" style="max-height:500px;overflow:scroll;">
                            <a class="mt-2 item text-white" v-for="a in accountList" v-bind:key="a.accountIdHash" href="#" v-on:click="setAccount(a)" >
                                <b>{{a.accountName}}</b>
                                <i class="fa fa-chevron-right pull-right"></i>
                                </a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import {mapActions} from "vuex";
import {mapGetters} from "vuex";
export default {
    data: function() {
        return {
            accountFilter:""
        }
    },
    computed:{
        ...mapGetters(["allAccounts","isLoaded"]),  
        loading() {
            return !this.isLoaded.includes('accounts');
        }, 
        accountList() {
            let acclist = this.allAccounts.slice(0);
            if(this.accountFilter) {
                let search = this.accountFilter.trim();
                acclist= acclist.filter(function (row){
                    if(!row.accountName)
                        return false;
                    var b = row.accountName.toLowerCase().indexOf(search.toLowerCase()) >=0;
                    if(b)
                        return true;
                        return false;

                });
            }
            return acclist;
        }
    }
    ,
    methods: {        
        ...mapActions(["setAccount"]),
    }
}
</script>

<style scoped>
 #accountlist {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
 }
 #accountlist .item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    background-color:#818dbf;
    /*background-color: #bcbcbc;*/
    border: 3px solid rgba(0,0,0,.125);
 }
 #accountlist .item:hover {
     background-color: #777;
 }

</style>