<template>
    <div>
    <div class="card mb-4">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                <span class="px-2"><b>Order number:</b> {{order.orderNumber}}</span>                
                <br/>
                <span class="px-2"><b>Date:</b>{{order.dateCreated}}</span>
                </div>
                <div class="col-md mt-1">
                <router-link to="/dash" class="btn btn-primary float-md-right">
                Back to dashboard
                </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="datacard card border-1">
        <div class="card-header text-white text-center">Order Lines</div>
        <div class="datacard card-body table-responsive mb-0">
        <table class="table table-striped table-bordered table-hover mb-0">
            <thead class="">
                <tr>
                    <th>Ean/Reference</th>
                    <th>Orderline text</th>
                    <th>Quantity</th>
                    <th>Error</th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="(l, idx) in orderlines" v-bind:key="idx">                    
                    <td>{{l.ean}}</td>
                    <td>{{l.orderlineText}}</td>
                    <td>{{l.quantity}}</td>
                    <td>
                        {{l.error}}
                    </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</div>
</template>
<script>
import Axios from "axios";
import {mapGetters} from "vuex";
export default {
    props:['id'],
    data: function(){
        return {
            orderlines:[]
        }
    },
    computed:{
        ...mapGetters(["allHanging","theBaseUrl"]),
        order(){
            let theid = this.id;
            let o = this.allHanging.filter(function(row){
                return row.orderId===theid;
            });
            return o[0];
        }
    },
    mounted(){
        let baseurl = this.theBaseUrl;
        let url = `${baseurl}/api/orders/hanging/${this.id}/lines`;
        console.log("hanginorder url",url);
        //let _this = this;
        Axios.get(url).then((response) => {
            this.orderlines = response.data;
        });
    }
}
</script>