import Vue from "vue";
import VueRouter from "vue-router";
//import Store from "../components/Store";
//import ShoppingCart from "../components/ShoppingCart";
import DashView from "../components/DashView";
import CatalogView from "../components/CatalogView";
import OrderView from "../components/OrderView";
import OrderHistoryView from "../components/OrderHistoryView";
import InventoryView from "../components/InventoryView";
import HangingOrderView from "../components/HangingOrderView";
import HangingOrderTotalsView from "../components/HangingOrderTotalsView";
import SkuBindingView from "../components/SkuBindingView";
import ProductBindingView from "../components/ProductBindingView";
import NewOrderView from "../components/NewOrderView";
import ManualOrders from "../components/ManualOrders";
import NewProductView from "../components/NewProductView";
import ManualProducts from "../components/ManualProducts";
//import PasswordReset from "../components/PasswordReset";
Vue.use(VueRouter);

export default new VueRouter({
    //mode: "history", /* removes '#' fraction based navigation */
    //base: process.env.BASE_URL,
    /*scrollBehavior () {
        return { x: 0, y: 0 }
      },*/
    routes:[
        {path:"/",component: DashView, meta:{title:"Dashboard"}},
        {path:"/dash",component: DashView, meta:{title:"Dashboard"}},
        {path:"/dash/hanging",component: DashView, meta:{title:"Dashboard"}},
        {path:"/dash/unbound",component: DashView, meta:{title:"Dashboard"}},
        {path:"/dash/duplicates",component: DashView, meta:{title:"Dashboard"}},
        {path:"/catalog",component: CatalogView, meta:{title:"Catalog"}},
        {path:"/orders",component: OrderView, meta:{title:"Orders"}},
        {path: '/orders/:id/history', name: 'orderhistory', component: OrderHistoryView, props: true, meta: { title: "Order History" } },
        {path:"/inventory",component: InventoryView, meta:{title:"Inventory"}},
        {path:"/dash/hanging/:id/lines",component: HangingOrderView , name:"hangingorderlines",props:true, meta:{title:"Hanging order"} },
        {path:"/dash/hanging/lines",component: HangingOrderTotalsView , name:"hangingorderlinetotals",props:true, meta:{title:"Hanging order totals"} },
        {path:"/dash/skus/:id/bindings",component: SkuBindingView , name:"skubindings",props:true, meta:{title:"SKU bindings"} },
        {path: '/catalog/:id/bindings', name: 'productbindings', component: ProductBindingView, props: true, meta: { title: "Product bindings" } },
        {path: '/login/reset', name: 'passwordReset', meta: { title: "Reset your password" } },
        {path: '/manualorders', name:"manualorders", component: ManualOrders, meta: { title: "New order" }  },
        {path: '/manualorder', name:"manualorder", component: NewOrderView,  props:true, meta: { title: "New order" }  },
        {path: '/manualproducts', name:"manualproducts", component: ManualProducts, meta: { title: "Manual products" }  },
        {path: '/manualproduct', name:"manualproduct", component: NewProductView,props:true, meta: { title: "Create product" }  },
        //{path: "/cart",component: ShoppingCart},
        {path: "*", redirect : "/"}
    ]  
})