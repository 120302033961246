<template>
    <div>                
        <div class="alert alert-info" v-if="currentSku">
            <div class="row">
                <div class="col-md-10">
                    <b>SKU number:</b> {{currentSku.skuNumber}}, <b>Alternate SKU:</b> {{currentSku.altSKU}}, <b>Description:</b> {{currentSku.skuDescription}}, <b>Date created:</b> {{currentSku.dateCreated}}
                </div>
                <div class="col-md-2">
                    <button class="btn btn-info pull-right" v-on:click="goBack()">Back to dashboard</button>                
                </div>
            </div>
        </div>
        <div class="datacard card border-1 mb-3">
        <div class="card-header text-white text-center">Currently linked products</div>
        <div class="datacard card-body table-responsive mb-0">
            <table class="table table-striped table-bordered table-hover mb-0">
                <thead>
                        <tr class="border-bottom">                
                            <th colspan="3">
                                <div v-if="isbundle" class="alert alert-warning">Warning! you have more that one product attached to this SKU.<br/> This may result in a false indication of stock if you sell this SKU independently.<br/>Are you sure this is what you want?<br/></div> 
                            </th>        
                            <th colspan="3">
                                <button class="btn btn-default pull-right" style="margin-left:6px" v-on:click="goBack()">Cancel</button>
                                <button v-on:click="showModal=true" class="btn btn-warning pull-right">Save binding</button>                            
                            </th>
                        </tr>
                        <tr v-if="skubindings.length">
                            <th>Remove</th>
                            <th>Ean/Product reference</th>
                            <th>Product name</th>
                            <th>Variant name</th>
                        </tr>
                    </thead>
                <tbody>
                    <tr v-for="(s, idx) in skubindings" v-bind:key="idx">
                        <td><button v-on:click="removeProduct(s,idx)" class="btn btn-default"><i class="fa fa-minus" aria-hidden="true"><span class="sr-only">Remove binding</span></i></button></td>
                        <td>{{s.ean}}</td>
                        <td>{{s.productName}}</td>
                        <td>{{s.productVariantName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
     <modal v-if="showModal" @close="showModal = false" @ok="saveBindings()" hasOk="true" hasCancel="true"  isWarning="true">
            <div slot="body">
                Are you sure you want to save the current bindings ? The changes you make has influence on the actual products being shipped on orders.
            </div>
            <!--
          you can use custom content here to overwrite
          default content
        -->
            <h3 slot="header">Save bindings ?</h3>
            <span slot="footer"></span>
     </modal>
     <modal v-if="showBindingErrorModal" @ok="showBindingErrorModal = false; showModal=false;" hasOk="true"  isWarning="true">
                <div slot="body">
                    {{ errorText }}                    
                </div>
                <h3 slot="header">An error occurred</h3>
                <span slot="footer"></span>
     </modal> 
    <div class="datacard card border-1">
        <div class="card-header text-white text-center">Available products</div>
        <div class="datacard card-body table-responsive mb-0">
            <table class="table table-striped table-bordered table-hover mb-0">
                <thead>
                    <tr>
                        <th colspan="4" class="border-bottom">
                            <form>
                                <div class="form-group form-horizontal ">
                                    <label for="findref">Filter</label>
                                    <input type="text" id="findref" class="form-control" v-model="search" placeholder="search for product reference or name" />
                                </div>
                            </form>
                        </th>
                    </tr>
                    <tr>
                        <th>Select</th><th>Ean/Product reference</th><th>ProductName</th><th>Variant name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(p, idx) in filteredProducts" v-bind:key="idx" v-bind:class="{'dimmed-row':p.hasBinding}">
                        <td><button v-if="!p.hasBinding" v-on:click="addProduct(p,idx)" :disabled="false" class="btn btn-default"><i class="fa fa-plus" aria-hidden="true"><span class="sr-only">Bind product</span></i></button></td>
                        <td>{{p.ean}}</td>
                        <td>{{p.productName}}</td>
                        <td>{{p.productVariantName}}</td>                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    </div>
</template>
<script>
import {mapGetters,mapActions} from "vuex";
import Axios from "axios";
import modal from "./Modal"
export default {
    components: {
        modal
    },
    props: ['id'],
    data: function () {
        return {
            currentbinding: [],
            skubindings: [],
            productstobind: [],
            showModal: false,
            search: undefined,
            errorText:"",
            showBindingErrorModal: false,
        }
    },
    computed: {
        ...mapGetters(["isLoaded","allUnboundSkus","allBindingProducts","theBaseUrl"]),
        filteredProducts() {
            let plist = this.productstobind.slice(0);
           
            if (this.search) {
                var search = this.search.trim();
                plist = plist.filter(function (row) {
                    if (!row.ean || !row.productName)
                        return false;
                    //console.log(row.ean.search(new RegExp(search, "i")));
                    var b = row.ean.search(new RegExp(search, "i"))>=0 || row.productName.search(new RegExp(search, "i"))>=0;
                    if (b)
                        return true;
                    return false;
                });
            }
            return plist;
        },
        currentSku() {
            let thisid=this.id;
            let s = this.allUnboundSkus.filter(function (row) {
                return row.skuId == thisid;
            });
            return s[0];
        },
        isbundle ()
        {
            if (this.skubindings.length > 1)
                return true;
            return false;
        },
        loading () {
            //return !this.$store.state.bindingProductsLoaded;
            return !this.isLoaded.includes('bindingproducts');
        }
    },
    methods: {     
        ...mapActions(["loadUnboundSkus","loadBindingProducts"]),   
        goBack: function () {
                this.$router.push('/dash');
        },        
        saveBindings: function () {
           
            var references = [];
            for (var i = 0; i < this.skubindings.length; i++) {
                references.push(this.skubindings[i].ean);
            }
            let me=this;
            
            /*Used escaped quotes to send json string
            this.$http.post('api/binding/skus/' + this.id, "\"" + ean + "\"" ).then(r=> {
                console.log(r.data);

            });*/
            //var me = this;
            Axios.post(`${this.theBaseUrl}/api/binding/skus/${this.id}`,references).then(r=>{
                console.log(r.data);
                //this.$store.dispatch('invalidate', { resources: 'products,unboundskus,bindingproducts,bindingskus'});
                this.showModal = false;
                this.$store.dispatch('loadUnboundSkus');
                this.$store.dispatch('loadBindingProducts');
                this.$router.push('/dash');

            }).catch(function (reason) {
                console.log("Error creating skubinding", reason);
                
                switch(reason.response.status) {              
                    case 404:
                        me.errorText="The binding could not be changed, there may be active orders containing this ean/reference."
                        break;
                    case 403:
                        me.errorText="You do not have sufficient permissions to perform this operation"
                        break;
                    default:
                        me.errorText="An error ocurred, the operation could not be performed"
                        break;
                }                                
                me.showBindingErrorModal = true;
            });

        },
        isAvailable: function (sku) {
            if (this.skubindings.length === 0 || this.bindings.some(function (el) {

                 return el.skuNumber !== sku.skuNumber;
            })) {
                return true;
            }
            console.log('not rendering ' + sku.skuNumber);
            return false;
        },
        addProduct(prod, index) {
            /*if (this.isbundle ===false) {*/
           
            this.productstobind.splice(index, 1);
                //this.skustobind.splice(index, 1);
            console.log('now remove at position:' + index);
            /*}*/
            this.skubindings.push(prod);
        },
        removeProduct(prod, index) {
            console.log('remove prod ' + prod.ean + ' index ' + index);
            this.productstobind.unshift(prod);
            this.skubindings.splice(index, 1);

        }
    },
    mounted() {
        
        if(!this.isLoaded.includes('unboundskus'))
            this.loadUnboundSkus();
        if(!this.isLoaded.includes('bindingproducts')){
            this.loadBindingProducts().then(()=>{
                this.productstobind =  this.allBindingProducts.slice(0);  
            })
        } else {
            this.productstobind =  this.allBindingProducts.slice(0);  
        }

/*
        this.$store.dispatch('loadBindingProducts').then(response =>{
            this.productstobind = this.$store.state.bindingProducts.slice(0);
            console.log(response.state);//FIX DUMMY
        });                        
*/      
        Axios.get(`${this.theBaseUrl}/api/binding/skus/${this.id}/bindings`).then(r=> {
            this.currentbinding = r.data;
        });


        var j = this.currentbinding.length;
        var i = this.productstobind.length;
        while (j--) {
            while(i--){
                if (this.productstobind[i].ean === this.currentbinding[j].ean) {
                    this.skubindings.push(this.productstobind[i]);
                    this.productstobind.splice(i, 1);
                    break;
                }
            }
        }
        window.scrollTo(0, top);
    }  
}
</script>
<style scoped>
.datacard table .dimmed-row {
    background-color: #d6d6d6;
    color:#adadad;

}
</style>