<template>
    <div style="background-color:#fff;">                    
            <nav id="topnav"  class="navbar navbar-expand-lg navbar-light bg-white w-100 position-fixed border-bottom">   
            <a class="navbar-brand" href="#">
                <img src="https://controller.dansk-e-logistik.dk/images/DEL-logo3.png" />
                <span >Controller:{{CurrentAccount.accountName}}
                <small  style="font-size: xx-small;">v0.1.3 ({{CurrentAccount.accountIdHash}})</small></span>
            </a>   
            <button  type="button" class="navbar-toggler collapsed border-white" v-on:click="toggleNav">
                    <span class="navbar-toggler-icon"></span>
            </button> 
            <div id="navimgs" class="w-100">             
            <div class="navbar-nav pull-right btn-group" style="box-shadow: #eee 3px 3px 5px;">
                <!--li  class="nav-item"-->
                    <a  href="#" v-on:click.prevent="refreshAll()" target="_self" class="nav-item nav-link btn bg-light">
                        <i class="fa fa-refresh fa-lg"></i>
                    </a>
                <!--/li-->
                <!--li  class="nav-item mx-2"-->
                    <router-link to="/dash" class="nav-item nav-link text-white btn" v-bind:class="{'btn-light':alertCount==0, 'btn-danger' : alertCount && allDuplicates.length, 'btn-warning': alertCount && allDuplicates.length==0, }">
                        <i class="fa fa-bell-o fa-lg"></i>
                        {{alertCount}}                        
                    </router-link>
                    <!--a  href="#" target="_self" class="nav-link text-white btn" v-bind:class="{'btn-light':alertCount==0, 'btn-danger' : alertCount && allDuplicates.length, 'btn-warning': alertCount && allDuplicates.length==0, }">
                        <i class="fa fa-bell-o fa-lg"></i>
                        {{alertCount}}                        
                    </a-->
                <!--/li-->
                <!--li  class="nav-item" -->
                    <a  href="/" target="_self" class="nav-item nav-link btn bg-light" v-on:click.prevent="logout()">
                        <i class="fa fa-sign-out fa-lg"></i>
                    </a>
                <!--/li-->
            </div>
            </div>                                                                   
            </nav>

            <nav id="sidenav-collapse" class="navbar position-fixed"  v-bind:class="navon ? 'd-block' : 'd-none d-lg-block' ">                
                    <ul  id="sidenav" class="navbar-nav sidebar flex-column list-group" v-bind:class="{lean: shrinkmenu}">
                    <!--ul  id="sidenav" class="navbar-nav sidebar flex-column" -->
                        <li class="nav-item">
                            <router-link class="nav-link" to="/dash" active-class="active" v-on:click.native="navon=false" title="Dashboard"><i class="fa fa-dashboard fa-lg"></i><span v-show="!shrinkmenu || navon==true">Dashboard</span></router-link>
                        </li>
                       <li class="nav-item">

                            <router-link class="nav-link" to="/orders" active-class="active" v-on:click.native="navon=false" title="Orders"><i class="fa fa-send-o fa-lg"></i><span v-show="!shrinkmenu || navon==true">Orders</span></router-link>
                        </li>
                        <li class="nav-item">

                            <router-link class="nav-link" to="/catalog" active-class="active" v-on:click.native="navon=false" title="Catalog"><i class="fa fa-folder-open fa-lg"></i><span v-show="!shrinkmenu || navon==true">Product catalog</span></router-link>
                        </li>
                        <li class="nav-item">

                            <router-link class="nav-link" to="/inventory" active-class="active" v-on:click.native="navon=false"  title="Inventory"><i class="fa fa-bar-chart fa-lg"></i><span v-show="!shrinkmenu || navon==true">Inventory</span></router-link>
                        </li>
                        <li class="nav-item">

                            <a href="/Web controller User manual.pdf" target="_blank" active-class="active" v-on:click="navon=false"  class="nav-link" title="User manual"><i class="fa fa-question-circle-o fa-lg"></i><span v-show="!shrinkmenu || navon==true">User manual</span></a>
                        </li>
                        <li class="btn" v-show="navon==false" v-on:click="shrinkmenu=!shrinkmenu">
                            <span class="nav-link pull-right"><i style="color:#3e4892"  class="fa-lg" v-bind:class="[shrinkmenu ? 'fa fa-chevron-right mr-1' :'fa fa-chevron-left mr-3']"></i></span>
                        </li>
                        <li class="nav-item" v-if="canManualOrders">
                            <router-link class="nav-link" to="/manualorders" active-class="active" v-on:click.native="navon=false"  title="Inventory"><i class="fa fa-pencil-square-o fa-lg"></i><span v-show="!shrinkmenu || navon==true">Manual orders</span></router-link>
                        </li>
                        <li class="nav-item" v-if="canManualProducts">
                            <router-link class="nav-link" to="/manualproducts" active-class="active" v-on:click.native="navon=false"  title="Inventory"><i class="fa fa-cube fa-lg"></i><span v-show="!shrinkmenu || navon==true">Manual Products</span></router-link>
                        </li>
                        <li class="nav-item" v-if="canManualOrders || canManualProducts">
                            <a href="/ManualOrdersGuideV1.pdf" target="_blank" active-class="active" v-on:click="navon=false"  class="nav-link" title="User manual"><i class="fa fa-question-circle-o fa-lg"></i><span v-show="!shrinkmenu || navon==true">Guide to manual orders</span></a>
                        </li>                        

                    <!-- /ul -->     
                    </ul>                
            </nav>
            <div id="ctrview"  class="container-fluid" v-bind:class="{lean: shrinkmenu}">
                    <div class="container-fluid main">
                    <router-view ></router-view>
                    </div>
                
            </div>
            <modal v-if="showRefreshModal" @close="logout()" @ok="refreshAuth()" hasOk="true" hasCancel="true" isWarning="true">
            <div slot="body">
            Your login has expired, press "Yes" to stay logged in.<br/>
            - Seconds remaining:{{ counterVal }}
            </div>
            <h3 slot="header">Are you still working ?</h3>
            <span slot="okbtn">Yes</span>
            <span slot="cancelbtn">No</span>
            <span slot="footer"></span>
            </modal>    
        </div>    
</template>
<script>
import {mapGetters} from "vuex";
import {mapActions} from "vuex";
import { useIdle } from '@vueuse/core'
import modal from "./Modal";
const cntval=60;
const IDLEVAL=3;
const EXP = 0;

export default {
    
    data: function () {
        return {            
            navon:false,
            shrinkmenu:false,
            idleObj:{idle:{value:false}},
            showRefreshModal:false,
            logoutTimerID:0,
            secCounterID:0,
            expTimerID:0,
            counterVal:cntval            
        }
    },
    components:{modal},
    methods :{
        ...mapActions(["login"]),
        toggleNav() {
            console.log("click toggleNav");
            this.navon = !this.navon;
            console.log(this.navon);
        },
        logout(){
            window.onbeforeunload = null;
            location.reload(true);
        },
        refreshAll(){
            console.log('refreshing all');
            this.$store.dispatch('refresh','all');
        },
        timeOut() {
            console.log("Timeout reached",this.idleObj.idle.value);
            if(this.idleObj.idle.value===true) {
                this.ShowRefreshModal()                
            } else {
                this.refreshAuth();
            }
        },
        ShowRefreshModal() {
            this.showRefreshModal=true;    
            this.logoutTimerID = setTimeout(()=>{
            this.logout();
            },this.counterVal *1000);
            this.secCounterID = setInterval(()=>{
                this.counterVal--;
            },1000)        
        },
        HideRefreshModal() {
            clearTimeout(this.logoutTimerID);
            clearInterval(this.secCounterID);
            this.counterVal=cntval;  
            this.showRefreshModal=false;
                          
        },

        async refreshAuth() {
            if(this.showRefreshModal){
                this.HideRefreshModal();
            }
            let tok = atob(this.$parent.token);            
            let u='', p='', occ=0;
            for(let i =0;i<tok.length;i++ ) {
                              
                if(tok.charAt(i)==='@') occ++;
                if(occ==2){
                    u = tok.substring(0,i)
                    p = tok.substring(i+1,tok.length)                 
                    break;
                }
            }            
            await this.login({userEmail:u,userPass:p});                       
            if(this.expTimerID > 0) {
            clearTimeout(this.expTimerID);
            }
            this.expTimerID = setTimeout(()=>{
                this.timeOut();
            },this.expireM *60*1000);    

        }
    },
    computed:{
            ...mapGetters(["isLoaded","allHanging","allDuplicates","allUnboundSkus","CurrentAccount","canManualOrders","canManualProducts","loginExpires"]),
            alertCount(){
                let n = this.allHanging.length + this.allUnboundSkus.length + this.allDuplicates.length;
                return n;
            },
            btnClass(){
                if (this.allDuplicates.length)
                    return "btn-danger";
                else if(this.allHanging + this.allUnboundSkus > 0)
                    return "btn-warning";
                else
                    return "btn success"
            },
            expireM(){
                if(EXP> 0){
                    return EXP;
                } else {
                    const exp = Math.floor(((this.loginExpires *1000)-Date.now())/60000);
                    return exp;    
                }
                //return 2;
            }

            
    },
    mounted(){
        
        //makes sure you always go to the dashboard when just logged in        
        if(this.$router.currentRoute.path !=='/dash')
            this.$router.push({path:"/dash"});
        //If inactive for 3 min, consider that idle
        this.idleObj = useIdle(IDLEVAL*60*1000);
        //onst self = this;
        //Alert or Refresh 1 min before auto logout
        
        if(this.expTimerID > 0) {
            clearTimeout(this.expTimerID);
        }
        this.expTimerID = setTimeout(()=>{
            this.timeOut();
        },this.expireM*60*1000);   
        //},29*60*1000);
        
    },
    created() {
        //Always warn when user is about to reload or close the app.
        window.onbeforeunload = function () { return false; };
    }
}
</script>

<style>
/* ALL */

.btn.btn-default {
    border-color:#bbb;
    color:#555;    
}
.btn.btn-default:hover {
    background-color: #ccc;
    border-color:#bbb;
    color:#fff;
}


#topnav {
    z-index: 101;
}
#navimgs {
    padding-right:15px;
}
#navimgs .nav-item {
    padding:5px 20px 5px;
}
#sidenav-collapse {      
    z-index: 100;
}
 #ctrview {
     padding-top:70px;
     
 }

.main {
    padding:15px;
}
.datacard {    
    border:none!important;
}
.datacard .card-header {
    background-color:#6f799e;
    border: solid 1px #6f799d;
}
.datacard .card-body{
    padding:0;
}
.datacard .table td,.datacard .table th {
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    vertical-align: middle;
    
}
.datacard .table th {
    border-bottom: 0;
     border-top: 0;
}
.datacard.inv .table td,.datacard.inv .table th {
    padding-top:0.9rem;
    padding-bottom:0.9rem;
}

.datacard .table td:first-child, .datacard .table th:first-child {
    border-left:none;
    border-right: none;
}
.datacard .table td:last-child,.datacard .table th:last-child {
    border-left:none;
    border-right: none;
}
.datacard .table tfoot td {
    border-bottom: none;
}
.datacard .table td {
    max-width:250px;;
    
}
.datacard .table tfoot {
    background-color: #efefef;
    
}

#sidenav li {
    border-bottom:solid 1px #ebebeb;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
}
#sidenav li a i {
    width: 32px;
     
}
#sidenav-collapse {
    padding:0;
    margin:0;
}

/* Mobile */
@media (max-width:992px){

    #ctrview {
        padding-top:120px;
    }
    #navimgs .navbar-nav{
        flex-direction: row;
       
    }
    #navimgs a {
         padding:0 10px;
    }
    #sidenav-collapse {
        width:100%;
        height:100%;
        padding-top:120px;
        background-color: #fff;

    }
    #sidenav li a {
        color:#3e4892;
    }

}
.navbar-expand-lg
{
    justify-content: space-between;
}
/* NOT Mobile */
@media (min-width: 992px) {
    #sidenav-collapse {        
        top:0px;
        margin-top:70px;
        left:0px;

        /*width:250px;*/
    }
    #sidenav {
        /* z-index: 1; */        
        width: 250px;
        
    }
    #sidenav.lean {
        /* z-index: 1; */        
        width: 50px;
        
    }
    #sidenav li a {
        /* z-index: 1; */
    color: #3e4892;
    text-decoration: none;
        
    }
    #ctrview {
        margin-left:250px;
        border-left: 1px solid #e7e7e7;
        min-height: 100vh;
        width:auto;
        background-color:#efefef;
    }
    #ctrview.lean {
        margin-left:50px;
        border-left: 1px solid #e7e7e7;
        min-height: 500px;
        width:auto;
        background-color:#efefef;
    }
}
</style>
