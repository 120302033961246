<template>
    <div class="datePick" style="position:relative">
        <label for="adate">{{labelText}}</label>
        <input type="text" class="adate form-control" ref="dateField" v-on:click="showcal" :value="value" readonly />
        <div v-show="isVisible" class="card dateform" style="min-width:100%;position:absolute;top:20px;left:0;background-color:#fff;margin-top:50px;z-index:102" v-on:mouseleave="mouseLeave" v-on:touchstart="touchStart" v-on:touchend="touchEnd">            
            <div class="card-header py-0">
                <div style="width:100%">
                    <div style="width:100px;margin:0 auto;">
                        <a href="#" style="float:left;" v-on:click.prevent="bckMonth()"><i class="fa fa-2x fa-caret-left"></i></a> 
                        <div class="current" style="float:left;margin:0 5px;padding-top:5px;width:65px;text-align:center"><span v-bind:content="dpMonth">{{dpMonth}}</span> <span>{{dpYear}}</span></div> 
                        <a href="#" style="float:left" v-on:click.prevent="fwdMonth()"><i class="fa fa-2x fa-caret-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <table class="table table-sm" style="width:100%;height:100%">
                    <thead>
                        <tr><th>man</th><th>tir</th><th>ons</th><th>tor</th><th>fre</th><th>lør</th><th>søn</th></tr>
                    </thead>
                    <tbody>
                        <tr v-for="n in calRows" v-bind:key="n">
                            <td class="dateVal" v-for="d in 7" v-bind:key="d"><a href="#" v-on:click.prevent="setDate(n,d)" v-bind:class="{'dimdate':notInMonth(n,d)}" >{{getNext(n,d)}}</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>                
    </div>
</template>
<script>


import moment from 'moment';
import Vue from 'vue';
export default {   
   //props: ['labelText', 'value', 'initDate','id'],
   props: ['labelText', 'initDate','id', 'state'],
    template: "#datepick",
    data: function () {
        return {
            
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            selectedDate: undefined,
            counter: 0,
            dates: [],
            dpMonth: 0,
            dpYear: 0,
            calRows: 5,
            isVisible: false
            //latch: _datePickerLatch //FIX
        }
    },
    computed: {
        //isVisible: function () {
            //if (this.latch.datepicker === this.id) //FIX
               // return true;
            //return false;
            
        //},
        value: {
            get: function () {
                var m = moment(this.selectedDate);
                return m.format("YYYY-MM-DD");
                //return this.selectedDate.toLocaleString();;
            },
            set: function (newVal) {
                //console.log("hej ");
                var m = moment(newVal, "YYYY-MM-DD");
                //var d = this.selectedDate;
                if (m.isValid()) {
                    this.selectedDate = m.toDate();
                    //console.log('test');
                }
                /*else
                {
                    this.selectedDate = d;
                }*/


            }


        },

        daysInMonth: function () {
            var d = this.selectedDate;
            return new Date(d.getFullYear(), d.getMonth(), 0).getDate();

        },
        startDay: function () {
            var d = this.selectedDate;
            return new Date(d.getFullYear(), d.getMonth(), 1).getDay();
        },
        statev: function(){
            return this.state.opened;
        }

    },
    watch: {
        selectedDate: function (val) {
            this.initDates();
            console.log(val); //FIX
        },
        value: function () {
            console.log("value changes");
            //this.doUpdate();
            this.$emit('input', this.value);
        },
        statev: function(val){
            console.log("state changed",val);
            if(val!=='none' && val!==this.id)
                this.isVisible=false;
        }


    },
    methods: {

        mouseLeave: function () {
            console.log('mouse leave');
        },
        touchStart: function () {
            console.log('touch start');
        },
        touchEnd: function () {
            console.log('touch end');
        },

        notInMonth: function (n, d) {

            return this.dates[n - 1][d - 1].getMonth() !== this.months.indexOf(this.dpMonth);

        },
        bckMonth: function () {

            var m1 = this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
            Vue.set(this, 'selectedDate', new Date(m1));

        },
        fwdMonth: function () {

            var m2 = this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
            Vue.set(this, 'selectedDate', new Date(m2));


        },
        getNext: function (w, t) {
            //var d = this.selectedDate;
            //var nxd = new Date(d.getFullYear(), d.getMonth(), this.mdays[w - 1][t - 1] - (this.startDay - 1));
            //this.dates[w - 1].push(nxd);
            var nxd = this.dates[w - 1][t - 1];
            return nxd.getDate();
        },
        setDate: function (n, d) {
            this.selectedDate = this.dates[n - 1][d - 1];
            this.isVisible=false;
        },

        showcal: function () {
            this.isVisible=!this.isVisible;
            this.state.opened=this.id;
        },
        initDates: function () {
            var mdays = [[1, 2, 3, 4, 5, 6, 7], [8, 9, 10, 11, 12, 13, 14], [15, 16, 17, 18, 19, 20, 21], [22, 23, 24, 25, 26, 27, 28], [29, 30, 31, 32, 33, 34, 35]];
            this.dates = [[], [], [], [], []];
            var thisDates = this.dates;
            var d = this.selectedDate;
            var thisStartDay = new Date(d.getFullYear(), d.getMonth(), 1).getDay();
            var dayCnt = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
            console.log(dayCnt);

            if (thisStartDay === 6) {
                if (dayCnt > 30) {
                    mdays.push([36, 37, 38, 39, 40, 41, 42]);
                    thisDates.push([]);
                }
            }

            if (thisStartDay === 0) {
                thisStartDay = 7; /*because monday is first day, not sunday*/
                if (dayCnt > 29) {
                    mdays.push([36, 37, 38, 39, 40, 41, 42]);
                    thisDates.push([]);
                }
            }


            this.calRows = thisDates.length;
            this.dpMonth = this.months[d.getMonth()];
            this.dpYear = d.getFullYear();
            mdays.forEach(function (item, index) {
                item.forEach(function (subitem, subindex) {

                    var nxd = new Date(d.getFullYear(), d.getMonth(), subitem - (thisStartDay - 1));
                    thisDates[index].push(nxd);
                    console.log(subindex); //FIX
                });
            });
            this.dates = thisDates;

        }
    },
    created() {

        var m = moment(this.initDate, "YYYY-MM-DD");
        //return m.format("YYYY-MM-DD HH:mm:ss");

        this.selectedDate = m.toDate();
        //var d = this.selectedDate;        

        this.initDates();



    } 
}
</script>
<style scoped>
    .dateform .dateVal .dimdate {
        color:#777;
    }
</style>