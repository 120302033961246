<template>
    <div class="row">
        <modal v-if="showLeaveModal" @close="showLeaveModal = false" @ok="discardOrder" hasOk="true" hasCancel="true" isWarning="false">
                <div slot="body">
                    Your order has not been saved, leaving will discard any changes
                </div>
                <h3 slot="header">Discard your order ?</h3>
                <span slot="footer"></span>
        </modal>
        <modal v-if="showTemplateExists" @close="showTemplateExists = false" @ok="updateTemplate" hasOk="true" hasCancel="true" isWarning="false">
                <div slot="body">
                    A template with this name already exists, overwrite ?
                </div>
                <h3 slot="header">Overwrite template ?</h3>
                <span slot="footer"></span>
        </modal> 
        <div class="col-12">
            <div class="card">
                <div class="card-body tmplatemgr">
                    <div>
                        <div class="form-row">
                            <div class="form-group col mb-0">
                                <button @click="cancelEdit" class="btn btn-secondary float-md-right mx-md-1 mb-1">
                                    Cancel
                                </button>                                                                
                                <button @click="saveOrder" class="btn btn-primary float-right mx-md-1 mb-1">Save order</button>
                                <button v-if="!showTemplateForm" class="btn btn-default float-right mx-md-1 mb-1" @click="showTemplateForm=true">Manage order templates</button>
                                <button v-else class="btn btn-default float-right mx-md-1 mb-1" @click="showTemplateForm=false">Hide order templates</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div v-show="showTemplateForm" class="col-12">
            <div class="card mt-2">
                <div class="card-header" style="background-color:#6f799e">
                    <div class="text-white">Order templates</div>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col">
                        <label for="currentTemplate">Template name</label> 
                        <input id="currentTemplate" v-model="currentTemplate" type="text" class="form-control col-md-6 mb-1">
                        <button @click="saveTemplate()" class="btn btn-default col-md-4">Save order as template</button>                        
                        </div>
                    </div>
                    <div class="form-row mt-3">
                        <div class="form-group col">                        
                            <label for="templateFinder">Load / Edit existing templates</label>
                            <input id="templateFinder" v-model="templateFilter" type="text" class="form-control rounded-top" placeholder="search templates">
                            <div class="card rounded-0" style="max-height:300px;overflow-y:scroll;">
                                <div class="card-body p-0">
                                    <ul class="list-group list-group-flush">
                                        <li v-for="t in filteredTemplates" :key="t.templateName" class="list-group-item text-left rounded-0 py-1">
                                            <a class="align-middle" href="#" @click.prevent="loadTemplate(t)">{{t.templateName}}</a>
                                            <button @click="deleteTemplate(t.templateName)" class="btn btn-default btn-sm pull-right"><i class="fa fa-trash fa-sm"></i></button>
                                        </li>                                        
                                    </ul>     
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <div v-if="formValidate && formValidationMessage" class="col-12 mt-4">
            <div class="alert alert-danger">                
                    {{formValidationMessage}}
            </div>
        </div>
        <div v-if="templateMessage" class="col-12 mt-4">
            <div class="alert alert-success">                
                    <div>{{templateMessage}}</div>                
            </div>
        </div>
        <div class="col-12">
            <div class="card mt-md-4 mt-sm-2 mt-2">
                <div class="card-header" style="background-color:#6f799e;border: solid 1px #dfdfdf;height:50px">
                    <div class="text-white">Shipment details
                        <i v-on:click="showForm=!showForm" v-bind:class="[showForm ? 'fa-chevron-circle-up':'fa-chevron-circle-down']" class="btn fa fa-xl pull-right text-white" style="font-size:1.6em;line-height:0.45em"></i>
                        <i v-on:click="showHelp=!showHelp"  class="btn fa fa-question-circle fa-xl pull-right  text-white" style="font-size:1.6em;line-height:0.45em"></i>
                    </div>                    
                </div>
                <div class="card-body">
                <div v-if="showForm" class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label for="OrderNumber">Order Number</label>
                            <input @blur="orderNumberBlur()" v-bind:placeholder="orderPrefix" maxlength="20" :disabled="currentId!=undefined" type="text" v-model="manualOrder.orderNumber" class="form-control" id="OrderNumber" :class="{'border-danger': formValidate && (!manualOrder.orderNumber || hasExistingOrder)}" required aria-describedby="OrderNumberHelp">
                            <small v-show="showHelp" class="form-text text-muted" id="OrderNumberHelp">Required - max {{orderNumberLength}} chars, <span v-if="orderPrefix"> must be prefixed with '{{orderPrefix}}'</span></small>
                        </div>                            
                        <div class="form-group">
                            <label for="CustomerNumber">Customer Number</label>
                            <input maxlength="20" v-model="manualOrder.orderData.customerNumber" type="text" class="form-control" id="CustomerNumber" aria-describedby="CustomerNumberHelp">
                            <small v-show="showHelp" id="CustomerNumberHelp" class="form-text text-muted">Optional - you may enter a customer number for your own reference</small>
                        </div>                        
                        <div class="form-group">
                         <label for="Company">Company</label>
                         <input v-model="manualOrder.orderData.address.company" type="text" class="form-control" id="Company" aria-describedby="CompanyHelp">
                         <small v-show="showHelp" class="form-text text-muted" id="CompanyHelp">Optional - Company name</small>
                        </div>
                    </div>
                    <div class="col-lg-4">  
                        <div class="form-group">
                         <label for="Firstname">First name</label>
                         <input v-model="manualOrder.orderData.address.firstName" type="text" class="form-control" id="Firstname" aria-describedby="FirstNameHelp" :class="{'border-danger': formValidate && !manualOrder.orderData.address.firstName}">
                         <small v-show="showHelp" class="form-text text-muted" id="FirstNameHelp">Required - receiver first name</small>
                       </div>
                        <div class="form-group">
                            <label for="Lastname">Last name</label>
                            <input v-model="manualOrder.orderData.address.lastName" type="text" class="form-control" id="Lastname" aria-describedby="LastNameHelp" :class="{'border-danger': formValidate && !manualOrder.orderData.address.lastName}">
                            <small v-show="showHelp" class="form-text text-muted" id="LastNameHelp">Required - receiver last name</small>
                        </div>

                        <div class="form-group">
                            <label for="Address">Address</label>
                            <input maxlength="50" v-model="manualOrder.orderData.address.address1" type="text" id="Address" class="form-control" aria-describedby="AddressHelp" :class="{'border-danger': formValidate && !manualOrder.orderData.address.address1}">
                            <small v-show="showHelp" id="AddressHelp" class="form-text text-muted">Required - receiver address</small>
                        </div>
                        <div class="form-group">
                            <label for="City">City</label>
                            <input v-model="manualOrder.orderData.address.city" type="text" id="City" class="form-control" aria-describedby="CityHelp" :class="{'border-danger': formValidate && !manualOrder.orderData.address.city}">
                            <small v-show="showHelp" id="CityHelp" class="form-text text-muted">Required - receiver city</small>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="row">
                        <div class="form-group col-md-6">
                            <label for="Postalcode">Postalcode</label>
                            <input v-model="manualOrder.orderData.address.zip" type="text" id="Postalcode" class="form-control" aria-describedby="PostalcodeHelp" :class="{'border-danger': formValidate && !manualOrder.orderData.address.zip}">
                            <small v-show="showHelp" id="PostalcodeHelp" class="form-text text-muted">Required - receiver zip / postalcode</small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="CountryCode">Country</label>
                            <input @blur="countryBlur()" maxlength="2" v-model="manualOrder.orderData.address.country" type="text" id="CountryCode" class="form-control" aria-describedby="CountrycodeHelp" :class="{'border-danger': formValidate && !countryCodeOk(manualOrder.orderData.address.country)}">
                            <!--select v-model="manualOrder.orderData.address.country" id="CountryCode" class="form-control"  aria-describedby="CountrycodeHelp" :class="{'border-danger': formValidate && !manualOrder.orderData.address.country}">
                                <option v-for="c in manualOrderShippingCountries" :key="c.isoCode" :value="c.isoCode">{{c.isoCode}}</option>
                            </select-->
                            <small v-show="showHelp" id="CountrycodeHelp" class="form-text text-muted">Required - (a valid two letter iso code)</small>
                        </div>
                        </div>                        
                        <div class="form-group">
                            <label for="Email">Email</label>
                            <input v-model="manualOrder.orderData.email" type="email" class="form-control" id="Email" aria-describedby="EmailHelp" :class="{'border-danger': formValidate && !manualOrder.orderData.email}">
                            <small v-show="showHelp" class="form-text text-muted" id="EmailHelp">Required - Customer email</small>
                        </div>                        
                        <div class="form-group">
                            <label for="Phone">Phone</label>
                            <input v-model="manualOrder.orderData.phone" type="text" class="form-control" id="Phone" aria-describedby="PhoneHelp" :class="{'border-warning': formValidate && !manualOrder.orderData.phone}">
                            <small v-show="showHelp" class="form-text text-muted" id="PhoneHelp">Optional - Customer phone - can be required for some carriers/destinations</small>
                        </div>
                        
                         <div class="form-group">
                            <label for="DeliveryMethod">Delivery method</label>
                            <select v-model="manualOrder.orderData.shippingform" id="DeliveryMethod" class="form-control" aria-describedby="DeliveryMethodHelp" :class="{'border-danger': formValidate && !manualOrder.orderData.shippingform}">
                                <option v-for="f in manualOrderShippingforms" :key="f.ShippingFormID" :value="f.shippingformID">{{f.title}}</option>
                            </select>
                            <small v-show="showHelp" id="DeliveryMethodHelp" class="form-text text-muted">Select a delivery method</small>
                        </div>
                    </div>
                    <div class="col-12">
                         <div class="form-group">
                            <label for="OrderNote">Order Note</label>
                            <input type="text" v-model="manualOrder.orderData.orderNote" id="OrderNpte" class="form-control" aria-describedby="OrderNoteHelp">
                            <small v-show="showHelp" id="OrderNoteHelpHelp" class="form-text text-muted">Optional - Order delivery instruktions</small>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="datacard card mt-2">
                <div class="card-header">
                    <div class="text-white">Order lines</div>     
                </div>
                <div class="card-body table-responsive">
                    <table class="table table-striped table-bordered table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Ean/Reference</th>
                                <th>Title</th>
                                <th>Quantity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ol in manualOrder.orderData.orderlines" :key="ol.eanRef">
                                <td>{{ol.eanRef}}</td>
                                <td>{{ol.title}}</td>
                                <td style="max-width:80px">{{ol.quantity}}
                                <input v-if="ol.isEdit==true" type="number" class="form-control" style="max-width:80px" v-model="ol.quantity"></td>
                                <td style="max-width:50px">
                                <button v-if="false" class="btn btn-default btn-sm mr-2" @click="ol.isEdit=true"><i class="fa fa-pencil"></i></button>
                                <button class="btn btn-default btn-sm" @click="deleteOrderLine(ol)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>                       
                    </table>
                </div>
                <div class="card-footer bt-0">
                    <div class="mx-2 mt-2"> 
                        <div class="form-row">
                            <div class="form-group col-md-8">
                            <input class="form-control" v-model="search" @blur="searchBlur" type="text" placeholder="start typing product reference or title">
                            <div v-if="search" class="card" style="max-height:300px;overflow-y:scroll">
                                <div class="card-body">
                                    <ul class="list-group list-group-flush">
                                        <li v-for="p in filteredProducts" :key="p.ean" class="list-group-item text-left rounded-0"><a href="#" @click.prevent="setNextOrderLine(p)">{{p.ean}} {{p.productName}} {{p.productVariantName}}</a></li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                            <div class="form-group col-md-2">
                                <input class="form-control" v-model="nextLineQty" type="number" placeholder="quantity" :disabled="nextOrderLine.ean==undefined">
                            </div>
                            <div class="form-group col-md-2">
                                <button class="btn btn-primary form-control" @click="addNextOrderLine" :disabled="cannotAddLine()">Add line</button>
                            </div>
                        </div>
                    </div>      
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//import { set } from 'vue/types/umd';
import {mapGetters,mapActions} from "vuex";
import modal from "./Modal"
export default {
    components:{
        modal
    },
    props: ['id','iscopy'],
    data: function () {
        return {
            currentId:undefined,            
            showHelp:false,
            showForm:true,
            showTemplateForm:false,
            showTemplateExists:false,
            templateMessage:null,
            manualOrder:{},
            manualOrderForCompare:{},
            search:"",
            nextOrderLine:{},
            nextLineQty:null,
            showLeaveModal:false,
            formValidate:false,
            formValidationMessage:null,
            modalfunc:null, //this is used to hold the next() function from the havigation guard, which is called by the navigation modal
            currentTemplate:null,
            templateFilter:null,
            ordernumlen:20,
            hasExistingOrder:false
            
            
        }
    },
    /*watch:{
        manualOrder: {
            handler: function(val,oldVal){console.log("watching manualOrder",val,oldVal);this.dataChanged=true},
            deep:true,
            immediate:true
        }
    },*/        
    methods:{
        ...mapActions(["updateOrderTemplate","deleteOrderTemplate","saveOrderTemplate","loadOrderTemplates","loadProductData","refresh","saveManualOrder","loadManualOrderShippingforms","loadManualOrderShippingCountries","loadManualOrderSettings","loadOrderByNumber"]),        
        async saveTemplate(){
            if(this.currentTemplate){
                if(this.orderTemplates.find(t =>{return t.templateName===this.currentTemplate})===undefined) {
                    let templateData = {
                        templateName:this.currentTemplate,
                        orderData:this.manualOrder.orderData
                    }
                    await this.saveOrderTemplate(templateData)
                    this.showTemplateForm=false
                    let me = this
                    this.templateMessage=`Template ${this.currentTemplate} saved`
                    setTimeout(function(){me.templateMessage=null},1500)
                } else {
                    this.showTemplateExists=true
                }
            }
        },
        async updateTemplate(){
            if(this.currentTemplate){
                let templateData = {
                        templateName:this.currentTemplate,
                        orderData:this.manualOrder.orderData
                    }
                await this.updateOrderTemplate(templateData)
                this.showTemplateExists=false;
                this.showTemplateForm=false
                let me = this
                this.templateMessage=`Template ${this.currentTemplate} saved`                
                setTimeout(function(){me.templateMessage=null},1500)

            }
        },
        loadTemplate(t){
            let data = JSON.parse(JSON.stringify(t.orderData))
            this.manualOrder.orderData = data;
            this.showTemplateForm=false
            let me = this
            this.currentTemplate = t.templateName;
            this.templateMessage=`Template ${t.templateName} loaded`
            setTimeout(function(){me.templateMessage=null},1500)


        },
        async deleteTemplate(t){
            await this.deleteOrderTemplate(t);
        },
        cannotAddLine(){
            if(this.nextOrderLine ==undefined)
                return true;
            if(this.nextLineQty == undefined)
                return true;
            if(this.nextLineQty == null)
                return true;
            if(this.nextLineQty < 1)
                return true;

        },
        cancelEdit(){
            this.manualOrderForCompare = null;
            this.$router.push({name:"manualorders"})
        },
        editOrderLine(l){
            console.log(l)
        },
        deleteOrderLine(l){
            var idx = this.manualOrder.orderData.orderlines.map(itm => itm.eanRef).indexOf(l.eanRef);
            ~idx && this.manualOrder.orderData.orderlines.splice(idx,1);
            
        },
        async saveOrder(){
            
            let isNewOrder = this.currentId==undefined


            if(this.manualOrderSettings.orderPrefix) {
                if(this.manualOrder.orderNumber==this.manualOrderSettings.orderPrefix){
                    this.manualOrder.orderNumber="";                    
                } 
            }                         
            
            //console.log("Is an update ?",!isNewOrder)                

            if(await this.formValidation()){
                await this.saveManualOrder({manualOrder:this.manualOrder,isUpdate:!isNewOrder});
                this.manualOrderForCompare = null
                this.$router.push({name:"manualorders"})
            } else {
                this.formValidate=true;
                this.showHelp=true;
            }
            
        },
        async orderExists(onum){         
            
            if(this.manualOrders.filter( o => o.orderNumber==onum).length>0){
                console.log("duplicate among manual orders");
                this.hasExistingOrder = true;
                return true;
            }
            else if(this.allOrders.filter(o=>o.orderNumber==onum).length>0){
                console.log("duplicate among all loaded orders");
                this.hasExistingOrder = true;
                return true;
            }
            else {
                await this.loadOrderByNumber(onum);
                if(this.allOrders.filter(o=>o.orderNumber==onum).length>0){
                
                    console.log("duplicate among archived orders");
                    this.hasExistingOrder = true;
                    return true;
                }
            
            }
            
            return false;
        },
        addNextOrderLine(){
            let nxt=this.nextOrderLine
            let s=nxt.ean + ' ' + nxt.productName;
                if(nxt.productVariantName){
                    s += ' ' +nxt.productVariantName
                }
            let qty=parseInt(this.nextLineQty)
            let ln = this.manualOrder.orderData.orderlines.find(l=>l.eanRef===nxt.ean)
            if(ln==undefined){ 
                this.manualOrder.orderData.orderlines.push({eanRef:nxt.ean,quantity:qty,title:s})
            } else {
                ln.quantity += qty
            }
            
            this.nextOrderLine={};
            this.nextLineQty=null;
            this.search=""
        },
        searchBlur(){
            let me = this;
            setTimeout(function () {                            
                if(me.nextOrderLine.ean!=undefined){
                    let nxt = me.nextOrderLine
                    let searchText=nxt.ean + ' ' + nxt.productName;
                    if(nxt.productVariantName){
                        searchText += ' ' +nxt.productVariantName
                    }
                    me.search=searchText; 
                }
            },500)
        },
        setNextOrderLine(p){
            this.nextOrderLine=undefined;
            let searchText=p.ean + ' ' + p.productName;
            if(p.productVariantName){
                searchText += ' ' +p.productVariantName
            }
            this.search=searchText;
            this.nextOrderLine = p;
        },
        discardOrder(){
            this.modalfunc()
        },
        orderNumberBlur(){
            if(this.currentId!=undefined) {
                return;
            }
            if(this.manualOrderSettings.orderPrefix) {
                if(this.manualOrder.orderNumber==this.manualOrderSettings.orderPrefix){
                    this.manualOrder.orderNumber="";
                    return
                }
                if(this.manualOrder.orderNumber.startsWith(this.manualOrderSettings.orderPrefix)){
                    return;
                }
                this.manualOrder.orderNumber=this.manualOrderSettings.orderPrefix+this.manualOrder.orderNumber;
            }
        },
        countryBlur(){
            if(this.manualOrder.orderData.address.country==undefined)
                return
            this.manualOrder.orderData.address.country=this.manualOrder.orderData.address.country.toUpperCase()
        },
        countryCodeOk(cn) {
            if(cn == undefined )
                return false;
            if(cn == "") 
                return false;
            if(this.manualOrderShippingCountries.filter(e=>e.isoCode==cn).length>0){
                    return true;
                } 
            return false;
        },
        async formValidation(){
            let isNewOrder = this.currentId==undefined;
            if(isNewOrder && this.manualOrder.orderNumber && await this.orderExists(this.manualOrder.orderNumber)) {
               this.formValidationMessage = "An order with this number exists already";
               return false;
            }

            if(
                this.manualOrder.orderNumber && 
                this.manualOrder.orderData.email &&                              
                this.manualOrder.orderData.address.address1 &&
                this.manualOrder.orderData.address.city &&
                this.manualOrder.orderData.address.country &&
                this.manualOrder.orderData.address.firstName &&
                this.manualOrder.orderData.address.lastName &&
                this.manualOrder.orderData.address.zip &&
                this.manualOrder.orderData.orderlines.length &&
                this.manualOrder.orderData.shippingform
                
                
            ){
                if(this.countryCodeOk(this.manualOrder.orderData.address.country )) {
                    this.formValidationMessage = "";   
                    return true;
                }
                
            }
            this.formValidationMessage = "You must fill out all required fields and/ or add orderlines";   
            return false;
        }
        
    },
    computed:{
        ...mapGetters(["orderTemplates","manualOrders","allProducts","allOrders","isLoaded","manualOrderShippingforms","manualOrderShippingCountries","manualOrderSettings"]),        
        orderPrefix(){
            if(this.manualOrderSettings && this.manualOrderSettings.orderPrefix){
                return this.manualOrderSettings.orderPrefix;
            }
            return "";
        },
        orderNumberLength() {
            let l = this.ordernumlen;
            if(this.manualOrderSettings && this.manualOrderSettings.orderPrefix){
                let pl = this.manualOrderSettings.orderPrefix.length;
                l -=pl;
            }
            return l;
        },
        
        filteredTemplates(){
            if(this.templateFilter){
                let tList = this.orderTemplates.slice(0)
                let search = this.templateFilter.trim()
                return tList.filter(function(row){
                    if (!row.templateName)
                        return false;
                    return row.templateName.search(new RegExp(search,"i")) >= 0
                });

            } else {
                return this.orderTemplates
            }
        },
        filteredProducts(){            
            if (!this.search)
                return [];
            let plist=this.allProducts.slice(0)            
            var search = this.search.trim();
            return plist.filter(function(row){
                if (!row.ean || !row.productName)
                        return false;
                    let b = row.ean.search(new RegExp(search, "i")) >= 0
                        || row.productName.search(new RegExp(search, "i")) >= 0
                        || (row.sku != null && row.sku.search(new RegExp(search, "i")) >= 0)
                        || (row.productVariantName != null && row.productVariantName.search(new RegExp(search, "i")) >= 0);
                    if(b)
                        return true;
                    return false;
                
            })
        }
    },
    beforeRouteLeave(to,from,next){
        
        //prevent navigation away if the order has changed
        if(this.manualOrderForCompare!=null){
            let unchanged = JSON.stringify(this.manualOrderForCompare) ===JSON.stringify(this.manualOrder);
            if(unchanged) {
                next()
            } else {
                this.modalfunc = next
                this.showLeaveModal=true        
                next(false)
            }
        } else {
            next()
        }
    },    
    created(){

        this.currentId = this.id;
        if(!this.isLoaded.includes('products'))
        {
            this.loadProductData()
        }
        if(!this.isLoaded.includes('manualordershippingforms')){
            this.loadManualOrderShippingforms();
        }
        if(!this.isLoaded.includes('manualordershippingcountries')){
            this.loadManualOrderShippingCountries();
        }
        if(!this.isLoaded.includes('ordertemplates')){
            this.loadOrderTemplates();
        }
        if(!this.isLoaded.includes('manualordersettings')){
            this.loadManualOrderSettings();
        }
        if(this.currentId != undefined)
        {            
            let o = this.manualOrders.find(o=>o.orderNumber === this.id);                        
            this.manualOrder= JSON.parse(JSON.stringify(o));
            this.manualOrder.orderStatus = 1;
            if(this.iscopy != undefined && this.iscopy===true) {                
                this.currentId=undefined
            } 
            if(o.orderStatus>1)
            {
                this.manualOrder.orderStatus = 3;    
            } 
            
        } else {
            this.manualOrder={                
                orderData:{
                    address:{},
                    orderlines:[]
                }
            }
            //this.loadManualOrders()
        }
        this.manualOrderForCompare = JSON.parse(JSON.stringify(this.manualOrder))
        if(this.iscopy != undefined && this.iscopy===true) {
                this.manualOrder.orderNumber += ' (copy)'                
        }
        console.log("created")
    },
    mounted(){
        /*this.$watch('manualOrder',{
            handler:function(val,oldVal){
                console.log("watching manualOrder",val,oldVal);this.dataChanged=true
                },
            deep:true
        })*/

        
        console.log("mounted")
    }
}
</script>
<style scoped>
.tmplatemgr button {
    min-width:100%;
}
/* NOT Mobile */
@media (min-width: 992px) {
.tmplatemgr button {
    min-width:200px;
}
}
</style>